/* ////john csss// */
/*Fonts//*/
@font-face {
  font-family: "averta-black";
  src: url(../fonts/AvertaStd-Black.ttf);
}

/* @font-face {
  font-family: "icemoon";
  src: url(../fonts/icomoon/fonts/icomoon.ttf);
}
.icomoon{
  font-family: "icemoon";
}
.icon-bullhorn:before {
  content: "\e91a";
} */

/*///end fonts//*/
@media screen and (max-width: 490px) and (min-width: 360px) {
  #footer {
    z-index: 1020;
    position: fixed;
    bottom: 0;
    padding: 0;
    border-top: none;
    line-height: 20px;
    font-weight: 600;
    background-color: #0f172a;
    margin: 0 !important;
    width: 100%;
  }
}

#footer {
  z-index: 1020;
  position: fixed;
  bottom: 0;
  padding: 0;
  border-top: none;
  line-height: 20px;
  font-weight: 600;
  background-color: #0f172a;
  margin: 0 !important;
  /* width: 100%; */
}

html {
  max-width: var(--html-main-width);
  margin: 0 auto;
  position: relative;

  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
}


html::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

.font-bold {
  font-weight: bold;
}

.emergency-button {
  color: white;
  background: #ff5733;
  border: #ff5733;
  box-shadow: 3px 3px 10px 0 #ff5733 !important;
  border-radius: 50% !important;
  transition: all 0s !important;
  position: fixed;
  bottom: 25px;
  right: 25px;
  z-index: 1000;
  width: 60px;
  height: 60px;
  font-size: 18px;
  outline: none !important;
}

.header-wrapper {
  display: flex;
  justify-content: space-between;
  padding-bottom: 5px;
}

div.intl-tel-input > div.flag-container {
  padding: 0 10px 0 10px !important;
}

div#phoneContainer > div.separate-dial-code > input.customInput {
  font-size: 0.95rem;
  padding-left: 89px !important;
}

div.intl-tel-input > div.flag-container > div.selected-flag:hover,
div.intl-tel-input > div.flag-container > div.selected-flag:focus,
div.intl-tel-input > div.flag-container > div.selected-flag:active,
.intl-tel-input.allow-dropdown .flag-container:hover .selected-flag {
  border: none;
  outline: none;
  background-color: transparent !important;
}

div.logout-btn-container {
  width: 100%;
}

div.logout-btn-container > button {
  width: 70%;
  margin: 0 auto;
}

.reportTable td,
.reportTable th {
  font-size: 10.5px !important;
}

.ul-news-list > li {
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.ul-news-list li:not(:last-child) {
  border-bottom: unset !important;
}

.ul-news-list > li.announcementUnread {
  background-color: #efefef;
}

.ul-news-list > li:last-of-type {
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
}

.ul-news-list > li:first-of-type {
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}

.rangeslider__handle > .rangeslider__handle-label,
.rangeslider__handle > .rangeslider__handle-tooltip {
  user-select: none;
}

.rangeslider .rangeslider__handle-tooltip {
  width: 50px;
}

.rangeslider__handle {
  left: 25px;
}

.hot-tag{
  position: absolute;
  background-image: url('../img/hot_tag.png');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 45px;
  height: 30px;
  top: -12px;
  left: 0px;
}

div.css-k1fzpy-control,
div.css-42kscm-control {
  padding-left: 15px;
}

.resultCell {
  text-align: center;
}

.action-icon-container {
  padding: 0 20px !important;
}

.action-icon-container > p {
  display: flex;
  flex: 0.8;
}

.action-icon-container > div {
  display: flex;
  flex: 0.2;
}

.game-image {
  height: 65px;
  width: 65px;
  object-fit: contain;
  border-radius: 0.5rem;
}

.game-container {
  padding: unset !important;
}

.dashboard .game-container {
  max-width: 90px;
  height: 125px;
}

.game-row {
  margin: 0 auto;
  display: flex !important;
  justify-content: space-between;
  flex-wrap: wrap;
}

.game-container > a {
  text-decoration: none;
}

.game-names > p {
  color: black;
  text-decoration: none;
}

.game-title {
  width: 100%;
}

@media (max-width: 400px) {
  .game-row {
    padding-left: unset;
  }
}

.rdt .rdtPicker .rdtDays {
  min-width: 150px !important;
}

.rdt .rdtPicker {
  right: auto !important;
}

.loading-overlay {
  display: none;
}

._loading_overlay_wrapper--active.loading-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #ffffffcf;
  z-index: 99999;
  /* z-index: 99; */
}

._loading_overlay_overlay {
  display: none !important;
}

._loading_overlay_wrapper--active.loading-overlay p {
  color: #1c2a4f;
  margin-top: 1rem;
}

.loading-overlay.hide {
  display: none !important;
}

.loading-overlay img {
  width: 50px;
}

.btn.btn-brand-gradient {
  border: none;
}

.pin-wrapper {
  height: 100vh;
  width: 100vw;
  background: black;
  opacity: 0.5;
  position: fixed;
  left: 0;
  top: 0;
}

.pin-number {
  position: fixed;
  align-items: center;
  width: 100%;
  background-color: white;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  padding: 30px;
  left: 0;
  bottom: 0;
  text-align: center;
}

#deposit > div > div > div,
#deposit > form > div > div > div > div,
#withdrawal > div > div > div,
#withdrawal > form > div > div > div > div {
  border: 0;
}

#deposit input::-webkit-input-placeholder,
#withdrawal input::-webkit-input-placeholder,
.deposit-part input::-webkit-input-placeholder,
.deposit-inner-panel input::-webkit-input-placeholder,
.internal-transfer input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: black;
  font-weight: normal;
}

.pool-result .pool-calendar .react-datepicker-wrapper input {
  font-size: 13px;
  color: #002e6c;
  background: #fff;
  border-radius: 12px !important;
}

.modal-content.edit-downline-modal {
  height: 85%;
  position: fixed;
  left: 0;
  bottom: 0;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  padding: 10px 10px 0 10px;
}

.modal-content.edit-downline-modal .modal-body {
  overflow: auto;
}

.modal-content.edit-downline-modal .modal-title {
  font-size: 16px;
}

.modal-content.edit-downline-modal .modal-header,
.modal-content.edit-downline-modal .modal-body,
.modal-content.edit-downline-modal .modal-footer {
  border: 0;
  color: #002e6c;
}

.edit-downline-modal .form-group label {
  margin: 0;
}

.change-pin-label {
  padding-left: 20px;
  font-size: 16px;
  color: #002e6c;
}

.setting-options-icon-bg {
  width: 45px;
  height: 45px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f0f8ff;
  box-shadow: 0 2pt 4pt #ffc15940;
  border-radius: 1rem;
  margin-bottom: 8px;
}

input.pincode-input-text {
  width: 35px !important;
  height: 35px !important;
  background: transparent !important;
  border-radius: 0 !important;
  border-bottom: 2px solid #002e6c !important;
}

.content-header.home-header.shared-header.news-details {
  height: 100px;
  /*border-radius: 0 0 5px 5px;*/
}

.inbox-icon-cont {
  width: 60px;
  height: 60px;
  margin: 0 auto;
  padding: 10px;
}

.inbox-icon-cont > svg {
  width: 100% !important;
  height: 100% !important;
}

.news-detail-title {
  text-align: center;
  color: #002e6c;
  font-size: 16px;
}

.login .lang-selector input {
  height: 30px;
}

.wallet-info .modal-body {
  padding: 1rem 2rem;
}

.forget-password .modal-body {
  max-height: 150px !important;
  min-height: 150px !important;
  text-align: center;
}

.owl-carousel {
  z-index: 0;
}

.job-title {
  width: 80%;
  border-right: 1px solid #d6dadd;
}

.job-button {
  text-align: center;
}

.banner-popup {
  position: fixed;
  align-items: center;
  width: 345px;
  height: 300px;
  background-color: white;
  padding: 20px;
  text-align: center;
  border-radius: 20px;
  margin: auto;
  inset: 0;
}

.banner-popup-wrapper {
  height: 100vh;
  width: 100vw;
  background: black;
  opacity: 0.5;
  position: fixed;
  left: 0;
  top: 0;
}

.banner-popup-content {
  font-family: cursive;
  font-size: 15px;
  margin-top: 15px;
}

.banner-popup-image-wrapper {
  margin: auto;
}

.banner-popup-image {
  width: 100%;
}

.banner-popup-close-button {
  color: white;
  background-color: #002e6c;
  border: none;
  border-radius: 10px;
  width: 25%;
  height: 30px;
  position: absolute;
  bottom: 15px;
  left: 0;
  right: 0;
  margin: auto;
}

.game-card {
  border-radius: 0;
  box-shadow: none;
}

.accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: -1px;
  background-color: transparent;
  font-family: "Montserrat";
  font-weight: bold;
  font-size: 18px;
  color: #002e6c;
}

.accordion > .card:not(:last-of-type) {
  border-bottom: 1px solid #002e6c4d;
}

.card-header {
  border-bottom: 1px solid #002e6c4d;
}

.grid-wrapper {
  display: grid !important;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 2fr;
  grid-gap: 0.5rem;
}

.games-row {
  text-align: center;
}

.game-category-label {
  position: absolute;
  left: 0.2rem;
  font-size: 80%;
  top: 0.2rem;
  display: block;
  padding: 0.2rem 0.4rem;
  text-transform: uppercase;
  color: #fff;
  letter-spacing: 0.05rem;
  text-shadow: 1px 1px 4px #000;
  background: rgba(22, 36, 56, 0.27);
  backdrop-filter: blur(4.5px);
  -webkit-backdrop-filter: blur(4.5px);
  border-radius: 10px;
  border: 1px solid hsla(0, 0%, 100%, 0.18);
}

.game-info {
  line-height: 0.8;
  text-align: center;
  color: #252525;
  padding: 10px 5px;
  border: 1px solid #ffc159;
  border-radius: 15px;
  position: relative;
  max-width: 90px;
  height: 100px;
  width: 100px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.game-name {
  font-family: "Montserrat";
  font-size: 13px;
  font-weight: 500;
  color: #002e6c;
  text-align: center;
}

.modal-body::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background-color: grey;
}

.modal-body::-webkit-scrollbar-thumb {
  background-color: #002e6c;
}

.games-lobby {
  margin-bottom: 5px;
}

.games-lobby .nav-item {
  font-family: "Montserrat";
  color: #002e6c;
  font-size: 15px;
  font-weight: 600;
  text-align: center;
  width: 100px;
}

.games-lobby .nav-item.active {
  background-color: #fe9f00;
  border-radius: 25px;
}

.carousel-left-right-btn {
  position: absolute;
  top: 115px;
  background-color: #002e6c;
  color: white;
  width: 13px;
  border-radius: 5px;
}

.carousel-img {
  width: 100%;
  height: 100vh;
  object-fit: contain;
}

.dashboard .carousel-img {
  height: 150px;
  display: block;
  object-fit: cover;
}

.full-screen-img {
  top: 155px;
  left: 0;
  position: absolute;
  width: 100%;
  height: 55%;
  padding: 15px;
}

.click-continue {
  position: absolute;
  left: 0;
  bottom: 50px;
  width: 100%;
  text-align: center;
  font-size: 20px;
  opacity: 0.5;
}

.close-popup {
  position: fixed;
  top: 0;
  right: 0;
  color: white;
  margin: 55px 30px;
  font-size: 20px;
  z-index: 999;
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 10px;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}

.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 9px;
  height: 9px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
  opacity: 0.3;
  transition: opacity 0.6s ease;
  border-radius: 30px;
}

.dashboard-carousel-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
}

.shareholder-dropdown {
  margin-left: 5px;
}

.nested-dropdown {
  float: right;
}

.badge-yes {
  background: transparent;
  color: #6fcb20;
  border: 1px solid #6fcb20;
}

.badge-no {
  background: transparent;
  color: #ff493f;
  border: 1px solid #ff493f;
}

.badge-isShareHolder {
  background: #2c4077;
  color: #29d5d9;
}

.badge-normal {
  background: #2c4077;
  color: #ffffff;
}

.card-package {
  border-radius: 8px;
  background: #1b284b;
}

.card-package .table .child-row td {
  border-color: transparent !important;
}

.card-package .table .child-row td.cat-prod {
  font-size: 9px !important;
}

.card-package .table .subtotal td {
  background: #f0f8ff;
  border: none;
}

.card-package .table .grandtotal td {
  background: #f0f8ff;
  border: none;
}

.card-table .table th {
  color: #29d5d9;
}

.table{
  color: #fff !important;
}
.table-kpi-summary th {
  color: var(--theme-color-text-table-th-header);
  font-size: 12px !important;
}

.table-kpi-summary {
  font-weight: 600;
  border-collapse: separate;
  border-spacing: 0px;
}

.table-kpi-summary tbody td {
  color: #fff;
  vertical-align: middle;
  font-size: 12px !important;
}

.table-kpi-summary tfoot td {
  color: #29d5d9;
  font-size: 12px !important;
  border-top: 1px solid #979797;
}

.table-kpi-summary td {
  padding-top: 12px;
  padding-bottom: 12px;
  /* border-bottom: 1px solid #979797; */
}

.summary-title {
  margin: 20px 0 20px 20px;
}

.claim-title {
  margin: 20px 0 20px 20px;
}

.category-product {
  padding-left: 20px !important;
}

.changePin-modal-header {
  text-align: center;
  color: #002e6c;
  font-size: 20px;
  font-weight: 600;
}

.nav-link {
  padding: 0.5rem 0;
}

.downline-nav .nav-item {
  flex: 1 1;
  text-align: center;
}

.downline-nav .nav-item .nav-link.active {
  color: #ffc159 !important;
  background-color: transparent;
  border-bottom: 3px solid;
}

.dashboard-top-right {
  display: block;
  color: white;
  font-weight: 100;
  font-size: 10px;
  margin-top: 5px;
}

.referral-category {
  margin: 4px;
  color: red;
}

.login-btn {
  color: white !important;
  text-transform: uppercase;
}

.login-bottom {
  color: white;
  position: absolute;
  bottom: 0;
  width: 100%;
  margin-bottom: 10px;
  font-size: 8px;
}

.login-bottom-left {
  float: left;
  margin-left: 67px;
}

.login-bottom-right {
  float: right;
  font-size: 10px;
}

.login-browser-icon {
  width: 14px;
  margin: 5px 8px 0 0;
}

.downline-group-checkbox {
  align-self: center;
}

.input-checkbox-custom {
  display: none;
}

.input-checkbox-custom ~ label .checked-icon {
  display: none;
  opacity: 0;
}

.input-checkbox-custom:checked ~ label .checked-icon {
  display: block;
  opacity: 1;
}

.input-checkbox-custom ~ label .unchecked-icon {
  display: block;
  opacity: 1;
}

.input-checkbox-custom:checked ~ label .unchecked-icon {
  display: none;
  opacity: 0;
}

.tnc-container {
  position: absolute;
  text-align: center;
  padding: 25px 25px 75px 25px;
  overflow-y: scroll;
  height: calc(100% - 56px);
  color: #002c68;
  font-weight: bold;
}

.tnc-container::-webkit-scrollbar {
  background-color: #efefef;
  width: 8px;
}

.tnc-container::-webkit-scrollbar-thumb {
  background-color: #002d6a;
}

.tnc-logo {
  width: 100px;
  background-color: #002d6a;
  padding: 18px;
  border-radius: 10px;
}

.tnc-top-text {
  font-size: 20px;
  margin-top: 15px;
  font-weight: bold;
}

.tnc-content {
  text-align: justify;
  margin-top: 15px;
}

.tnc-label {
  border-bottom: 1px solid;
  margin-top: 20px;
  color: #002c68;
}

.tnc-list {
  display: flex;
}

.tnc-bullets-wrapper {
  margin-left: 50px;
}

.tnc-bullets {
  display: list-item;
}

.tnc-agree-label {
  display: inline-flex;
  color: white !important;
  padding-left: 0 !important;
  margin-top: 5px;
  white-space: break-spaces;
}

.tnc-popup-wrapper {
  height: 100vh;
  width: 100vw;
  background: black;
  opacity: 0.5;
  position: fixed;
  left: 0;
  top: 0;
}

.tnc-popup .tnc-popup-header {
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  color: #002c68;
  margin-bottom: 15px;
}

.tnc-popup {
  position: fixed;
  align-items: center;
  width: 75vw;
  height: 75vh;
  background-color: white;
  padding: 20px 7px 20px 15px;
  left: 12vw;
  top: 15vh;
  border-radius: 20px;
  z-index: 2;
  display: grid;
}

.tnc-popup .tnc-container {
  overflow-y: scroll;
  overflow-x: clip;
  height: 50vh;
  margin-bottom: 10px;
  text-align: justify;
  font-size: 12px;
  color: #002c68;
  letter-spacing: 0.2px;
  padding: 0;
  padding-right: 8px;
  position: relative;
  font-weight: normal;
}

.tnc-popup .tnc-container::-webkit-scrollbar {
  background-color: #efefef;
  width: 8px;
}

.tnc-popup .tnc-container::-webkit-scrollbar-thumb {
  background-color: #002d6a;
}

.tnc-popup .tnc-container .tnc-content {
  margin-top: 0;
}

.tnc-popup .tnc-container .tnc-top-text {
  color: #ffc159;
  margin-top: 20px;
  margin-bottom: 0.5rem;
  border-bottom: none;
  font-size: 15px !important;
}

.tnc-popup .tnc-container .tnc-label {
  color: #ffc159;
  margin-top: 20px;
  border-bottom: none;
}

.top-banner .text-and-searchbar {
  width: 100%;
  text-align: center;
  position: absolute;
  margin-top: -120px;
  left: 0;
}

.top-banner .text-and-searchbar .banner-text {
  font-size: 25px;
  text-transform: uppercase;
  color: white;
  margin-bottom: 10px;
}

.banner-searchbar .search-box {
  background-color: white;
  width: 200px;
  height: 30px;
  border-radius: 20px;
  border: transparent;
  padding: 10px;
  font-size: 10px;
}

.banner-searchbar .search-box:focus-visible {
  outline: transparent;
}

.banner-searchbar .search-box::placeholder {
  color: #002e6c;
}

#hot-games-list {
  margin: 0 20px;
  justify-content: space-around;
}

.hot-games-label {
  margin: 12px 27px;
}

.hot-games-tile {
  text-align: -webkit-center;
  max-width: 100px;
}

.lobby-games-img {
  width: 100px;
  height: 100px;
  /* background-size: contain; */
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  border-radius: 10px;
  margin: 5px 0px;
  position: relative;
}

.hot-games-text {
  color: #002e6c;
  font-weight: bold;
  font-size: 12px;
  margin: 0 5px;
  overflow-wrap: break-word;
}

.all-games {
  margin-top: 20px;
}

.games-lobby .nav-item.active {
  background-color: rgba(254, 159, 0, 0.2);
  border-radius: 0;
  border-bottom: 2px solid rgb(254 159 0);
}

.all-games .tab-content {
  margin: 0 20px;
}

.banner-background {
  width: 100%;
  opacity: 0.4;
  min-height: 130px;
  height: 250px;
  background-size: cover;
  background-position: center center;
}

.banner-icon {
  width: 32px;
  height: 22px;
  margin: 20px auto;
  background-size: cover;
  background-position: center center;
}

.banner-icon-wrapper {
  position: absolute;
  top: 0;
  height: 25px;
  width: 100%;
}

.banner-close-btn {
  position: absolute;
  top: 0;
  right: 0px;
  font-size: 25px;
  width: 30px;
  height: 30px;
  text-align: center;
}

.btn-pregame-popup {
  width: 40%;
}

#dashboard-pregame-popup {
  margin: auto;
}

#dashboard-pregame-popup .modal-pregame-popup {
  height: 100vh;
  background-color: #02285a;
  border: 0;
  border-radius: 0;
}

#dashboard-pregame-popup .pregame-popup-header {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: white;
}

#dashboard-pregame-popup .col-form-label {
  color: white;
}

#dashboard-pregame-popup .pregame-popup-banner {
  width: 100%;
  opacity: 0.7;
  height: 200px;
  background-size: cover;
  background-position: center center;
}

#dashboard-pregame-popup .wallet-info {
  display: flex;
  color: white;
  font-size: 15px;
  justify-content: space-evenly;
  padding: 10px;
  align-items: center;
  text-align: center;
}

#dashboard-pregame-popup .vertical-line {
  background-color: white;
  margin: 0;
}

#dashboard-pregame-popup .transfer-input {
  display: flex;
  justify-content: center;
  margin: 15px;
  color: #ffc159;
  flex-direction: column;
  text-align: center;
}

#dashboard-pregame-popup .transfer-input > * {
  align-self: center;
  margin: 0 auto !important;
}

#dashboard-pregame-popup .transfer-input .currency {
  margin-top: 12px;
}

#dashboard-pregame-popup .transfer-balance {
  display: flex;
  justify-content: space-between;
  margin: 0 20px;
}

.transfer-amount-wrapper {
  color: white;
  margin: 20px 0;
}

.transfer-input-label {
  text-align: center;
}

.transfer-amount {
  background-color: rgba(255, 255, 255, 0.1) !important;
  box-shadow: none !important;
  width: 200px !important;
  overflow-x: hidden;
  margin: 15px !important;
  color: #ffc159;
  font-size: 30px;
  text-align: center;
  line-height: 30px;
}

.transfer-amount:focus-visible {
  outline: 0;
}

.transfer-amount:hover {
  box-shadow: none !important;
}

.transfer-amount:focus {
  color: #ffc159;
  border-color: transparent;
}

.transfer-amount::placeholder {
  color: #ffc159 !important;
  font-size: 30px;
}

.transfer-btn {
  text-align: center;
}

.transfer-btn .btn {
  width: 125px;
  font-size: 15px;
}

.bonus-and-commission {
  color: white;
  padding: 25px;
}

.bonus-and-commission .bonus {
  margin-bottom: 15px;
}

.bonus-and-commission .commission {
  margin-bottom: 15px;
}

.masterProductContent {
  color: white;
  padding: 14px 18px;
  margin: 25px 5px 0;
  background-color: #032451;
  border-radius: 16px;
  word-wrap: break-word;
  line-height: 2rem;
}

.start-btn {
  text-align: center;
}

.dashboard {
  overflow-y: scroll;
}

.dashboard::-webkit-scrollbar {
  display: none;
}

.dashboard .games-menu {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 2fr;
}

.user-name {
  color: white;
}

.user-info {
  display: flex;
  justify-content: space-between;
  height: 60px;
  color: white;
}

.wallet-and-commission {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  font-size: 13px;
}

.user-info-right {
  display: flex;
  align-self: center;
  width: 235px;
  /* width: 200px; */
  justify-content: space-between;
  align-items: flex-end;
}

.user-info-right .text {
  display: block;
  color: #ffc159;
  font-weight: 100;
  font-size: 12px;
  margin-top: 5px;
  white-space: nowrap;
}

.user-info-right .kpi-and-settings {
  display: grid;
  text-align: center;
  width: 55px;
  justify-items: center;
  margin: 5px;
}

.user-info-right .reward .reward-icon {
  width: 22px;
  height: 22px;
  background-size: cover;
  background-position: center center;
}

.user-info-right .referral .referral-icon {
  width: 22px;
  height: 22px;
  background-size: cover;
  background-position: center center;
}

.user-info-right .kpi .kpi-icon {
  width: 28px;
  height: 28px;
  background-size: cover;
  background-position: center center;
}

.user-info-right .contact-us .contact-us-icon {
  width: 28px;
  height: 28px;
  background-size: cover;
  background-position: center center;
}

.dashboard-menu {
  display: flex;
  justify-content: space-between;
  height: 80px;
  align-items: flex-end;
  text-align: center;
}

.dashboard-menu .menu-name {
  color: white;
  margin-top: 6px;
  font-size: 12px;
  font-weight: bold;
  white-space: nowrap;
}

.dashboard-menu .menu-icon {
  width: 25px;
  margin-top: 4px;
}

.promo-banner-scroll {
  height: 120px;
  margin: 15px 0px;
  overflow-x: scroll;
  display: flex;
}

.promo-banner-scroll::-webkit-scrollbar {
  display: none;
}

.promo-banner-scroll .promo-banner {
  margin-right: 20px;
}

.games-content {
  display: flex;
  width: 100%;
  margin-top: 30px;
}

.games-content::-webkit-scrollbar {
  display: none;
}

.games-content .sidenav-menu .category-tile {
  width: 70px;
  height: 70px;
  background-color: #ffc159;
  text-align: -webkit-center;
  padding-top: 12px;
  margin-bottom: 20px;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.games-content .game-category-label {
  font-size: 55%;
}

.games-content .game-info {
  height: 100px;
  width: 100px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.games-content .sidenav-menu .nav-link.active {
  background-color: white;
}

.games-content .sidenav-menu .nav-item {
  justify-content: center;
  width: 75px;
}

.games-content .tab-content {
  height: 700px;
  width: 100%;
  overflow-y: scroll;
}

.games-content .tab-content::-webkit-scrollbar {
  width: 3px;
  height: 0;
  background-color: transparent;
}

.games-content .tab-content::-webkit-scrollbar-thumb {
  background-color: grey;
  border-radius: 10px;
}

.category-list .category-icon {
  width: 30px;
  height: 25px;
  background-size: cover;
  background-position: center center;
}

.category-list .category-text {
  color: #002e6c;
  font-size: 11px;
  margin-top: 10px;
  font-weight: bold;
}

.games-menu {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  justify-items: center;
}

.games-menu .game-name {
  color: white;
}

.menu-link {
  max-width: 65px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (max-width: 299px) {
  .dashboard .games-menu {
    grid-template-columns: repeat(2, 1fr) !important;
  }
}

@media (max-width: 321px) {
  .user-name {
    font-size: 12px;
  }

  .games-content .sidenav-menu .nav-item {
    width: 50px;
  }

  .games-content .sidenav-menu .category-tile {
    width: 48px;
    height: 48px;
    border-radius: 10px;
  }

  .category-list .category-icon {
    width: 22px;
    height: 22px;
  }

  .category-list .category-text {
    margin-top: 5px;
  }

  .menu-link {
    max-width: 50px !important;
  }

  .wallet-and-commission {
    font-size: 12px !important;
  }

  .user-info {
    height: 80px !important;
  }

  .user-info-right {
    width: 175px !important;
  }

  .user-info-right .text {
    font-size: 10px !important;
  }

  .user-info-right .kpi-and-settings {
    width: 50px !important;
  }

  .dashboard-menu .menu-name {
    font-size: 10px !important;
  }

  .bonusComm-option-select {
    width: 65px !important;
    font-size: 12px;
    margin: 0 5px !important;
  }

  .edit-plus-minus-icon {
    width: 18px !important;
    height: 18px !important;
  }
}

@media (max-width: 281px) {
  .games-content .game-info {
    height: 80px !important;
    width: 80px !important;
  }

  .dashboard .game-container {
    height: 90px !important;
  }

  .edit-percent-value {
    width: 40px !important;
  }
}

@media (max-width: 361px) {
  .games-content .game-info {
    height: 75px !important;
    width: 75px !important;
  }

  .payment-option-card .options .option .option-image {
    height: 45px !important;
    width: 45px !important;
    margin: 0 5px !important;
    border-radius: 5px !important;
    font-size: 10px;
  }

  .payment-option-card .options .option .option-text {
    font-size: 12px;
  }

  /* puishan working*/
  .modern-timer-box {
    grid-column-gap: 3px !important;
    column-gap: 3px !important;
  }

  .modern-overlay {
    /*left: 20px !important;*/
  }

  .modern-clock-box > div > .modern-per-time-box {
    /* width: 22px !important;
        height: 35px !important; */
    width: 20px !important;
    height: 25px !important;
  }

  .modern-per-time-digit {
    padding-top: 4px !important;
    font-size: 10px !important;
  }
  /* end by puishan */
}

@media (max-width: 374px) {
  .dashboard-v2-wrapper {
    padding: 10px !important;
  }

  .games-content .game-info {
    height: 80px;
    width: 80px;
  }
}

@media (max-width: 401px) {
  .dashboard-v2-wrapper {
    padding: 10px;
  }

  .games-content .sidenav-menu .nav-item {
    width: 60px;
  }

  .games-content .sidenav-menu .category-tile {
    width: 60px;
    height: 60px;
  }

  .category-list .category-icon {
    width: 25px;
    height: 22px;
  }

  .category-list .category-text {
    margin-top: 5px;
  }

  .games-content .game-info {
    height: 80px;
    width: 80px;
  }

  .dashboard .game-container {
    height: 95px;
  }

  .edit-percent-value {
    width: 40px !important;
  }

  .row-data-1 {
    font-size: 12px !important;
  }

  .row-data-2 {
    width: 80% !important;
    font-size: 10px !important;
  }

  .small-text-max .row-data-1 {
    font-size: 10px;
  }

  .edit-plus-minus-icon {
    width: 28px !important;
    height: 28px !important;
  }

  /*.row-data-4 div {
        width: 50px !important;
        font-size: 13px;
    }*/
}

@media (max-width: 415px) {
  .row-data-2 {
    font-size: 11px;
  }
}

.right-menu {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  height: 100vh;
}

.right-menu-main {
  right: 0;
  top: 0;
  height: 100%;
  width: 100px;
  background-color: #002f6d;
  overflow-y: scroll;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}

.right-menu-main::-webkit-scrollbar {
  display: none;
}

.right-menu-item {
  width: 100%;
  height: 90px;
  color: white;
  text-align: center;
  display: grid;
  padding: 15px;
  justify-content: center;
  justify-items: center;
  align-items: center;
}

.right-menu-item .item-icon {
  width: 25px;
  height: 22px;
}

.right-menu-item .item-name {
  color: #ffc159;
  font-size: 12px;
}

.right-menu-hr {
  background-color: #ffc159;
  width: 75px;
  margin: 0 auto;
}

.right-menu-arrow-wrapper {
  background-color: #002f6d;
  width: 30px;
  height: 60px;
  margin-top: 50px;
  margin-right: -1px;
  color: #ffc159;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.right-menu-arrow-wrapper .right-menu-arrow {
  font-size: 20px;
  margin: 20px 10px;
}

.lobby-back-button {
  position: absolute;
  top: 20px;
  left: 20px;
  color: white;
  width: 19px;
  height: 32px;
  background-repeat: no-repeat;
  z-index: 100;
}

.pregame-modal-body .display-wrapper {
  margin-bottom: 10px;
}

.modal-pregame-popup .start-btn-wrapper {
  margin: 10px 0;
  padding: 0 50px;
  position: absolute;
  bottom: 0;
  left: 0;
}

.video {
  width: 100%;
  height: auto;
  min-height: 175px;
  border: 0;
  border-radius: 10px;
}

.video-name {
  font-size: 15px;
  color: #002e6c;
  font-family: "Montserrat", sans serif;
  font-weight: 600;
}

.dashboard .carousel-indicators {
  bottom: 0;
}

.dashboard .carousel-indicators li {
  width: 5px;
  height: 5px;
  margin-right: 5px;
  margin-left: 5px;
}

.settings-radio-btn {
  margin-top: 4px;
  margin-right: 10px;
}

.settings-radio-btn-label {
  color: #002e6c;
  margin-left: 5px;
}

.image-sample {
  height: 220px;
  width: 150px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

.modal-body .game-container .game-info {
  margin: 0 auto 0.5rem;
}

.dashboard-v2-wrapper {
  /* padding: 20px 20px 0; */
  padding: 10px 20px 0;
}

.vid-tutorial-top {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 25px;
  height: 25px;
  margin-left: 25px;
}

.deposit-record-btn {
  color: #002c68;
  margin-right: 8px;
  margin-bottom: 18px;
  font-weight: bold;
}

.withdrawal-record-btn {
  color: #002c68;
  margin-right: 8px;
  margin-bottom: 10px;
  font-weight: bold;
}

.comm-history-btn {
  color: #002c68;
  margin: 20px 0px;
  font-weight: bold;
}

#custom-lobby .hot-games-label label {
  color: #002e6c;
}

.shareholder-nav {
  justify-content: space-around;
}

.shareholder-nav .nav-item .nav-link.active {
  color: #ffc159 !important;
  border-bottom: 2px solid #ffc159;
}

.claim-history-btn {
  color: #002c68;
  margin-right: 8px;
  font-weight: bold;
  margin-bottom: 10px;
}

.coming-soon-label {
  background-color: red;
  margin-right: 15px;
  font-size: 7px;
  text-transform: uppercase;
  border-radius: 10px;
  height: 10px;
  width: 100%;
  white-space: nowrap;
  font-weight: bold;
}

#contact-us .contact-list li {
  box-shadow: none;
}

.edit-plus-minus-icon {
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 30px;
  height: 30px;
}

.row-data-1 {
  color: #002e6c;
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 15px;
  margin-right: 10px;
}

.small-text-max .row-data-1 {
  margin-top: -15px;
  font-style: italic;
}

#referral-detail-modern .edit-referral-table .row-data-1 {
  color: white;
}

#edit-downline-modern .edit-downline-table .row-data-1 {
  color: white;
}

.row-data-2 {
  color: #002e6c;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.row-data-2 .txt-1 {
  font-weight: bold;
  font-size: 14px;
}

.row-data-2 .txt-2 {
  font-style: italic;
}

#referral-detail-modern .edit-referral-table .row-data-2 {
  color: white;
}

#edit-downline-modern .edit-downline-table .row-data-2 {
  color: white;
}

.row-data-3 {
  color: #002e6c;
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
  margin-right: 5px;
}

#referral-detail-modern .edit-referral-table .row-data-3 {
  color: white;
}

#edit-downline-modern .edit-downline-table .row-data-3 {
  color: white;
}

.row-data-4 div {
  width: 55px;
}

.row-data-max {
  background: #002e6c;
  font-weight: bold;
  color: white;
  border: 1px solid #002e6c;
  font-size: 15px;
  padding: 5px 10px;
  border-radius: 10px;
  text-align: center;
  cursor: pointer;
}

.small-text-max {
  font-size: 11px;
  display: flex;
}

.edit-percent-value {
  width: 60px;
  text-align: center;
  background: transparent;
  border: none;
  font-size: 14px;
}

.edit-percent-value:focus-visible {
  outline: none;
}

#referral-detail-modern .edit-percent-value {
  color: white;
}

#edit-downline-modern .edit-percent-value {
  color: white;
}

.edit-percent-value::-webkit-inner-spin-button {
  display: none;
}

.edit-downline-table,
.edit-referral-table {
  width: 100%;
}

.edit-downline-table {
  width: 100%;
}

.edit-referral-table .data-row {
  display: flex;
  padding: 10px;
  align-items: center;
}

.edit-downline-table .data-row {
  display: flex;
  padding: 10px;
  align-items: center;
}

.edit-referral-table .data-row.category:not(:nth-child(1)) {
  margin-top: 25px;
}

.edit-downline-table .data-row.category:not(:nth-child(1)) {
  margin-top: 25px;
}

.username-and-password {
  color: white;
  margin-left: 25px;
  margin-bottom: 50px;
}

.username-and-password .details.username {
  margin-bottom: 15px;
}

.username-and-password .details {
  display: flex;
  align-items: center;
}

.username-and-password .details-label {
  width: 100px;
}

.details.username .username-input {
  background-color: transparent;
  border: none;
  color: white;
  width: 125px;
}

.details.username .username-input:focus-visible {
  outline: none;
}

.details.password .password-input {
  background-color: transparent;
  border: none;
  color: white;
  width: 125px;
}

.details.password .password-input:focus-visible {
  outline: none;
}

.pregame-modal-body {
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 10px;
}

.pregame-modal-body::-webkit-scrollbar {
  display: none;
}

#referral-detail .edit-percent-value {
  background: white;
  border-radius: 10px;
}

.refresh-password-btn {
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
  margin-left: 20px;
}

.referral-copy-btn {
  cursor: pointer;
  width: 20px;
  position: absolute;
  right: 35px;
  margin-top: 5px;
}

.edit-referral-hr {
  background-color: grey;
  opacity: 0.3;
}

.bonusComm-option-select {
  height: 30px;
  border-radius: 15px;
  padding: 0 15px;
  margin: 0 15px;
  appearance: none;
  text-align: center;
  width: 70px;
  font-size: 13px;
  margin: 0 8px;
  background: white;
  color: black;
}

.bonusComm-option-select:focus-visible {
  outline: none;
}

.bonusComm-option-value {
  background: white;
  color: black;
}

.bonusComm-option-value::-webkit-scrollbar {
  width: 5px;
}

.data-row.bg-color.classic {
  background-color: #c8c8e882;
  border-radius: 10px;
}

.data-row.bg-color.modern {
  background-color: #043e8d;
  border-radius: 10px;
}

.data-row.bg-color-1.classic {
  background-color: #c8c8e882;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.data-row.bg-color-1.modern {
  background-color: #043e8d;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.data-row.bg-color-2.classic {
  background-color: #c8c8e882;
}

.data-row.bg-color-2.modern {
  background-color: #043e8d;
}

.data-row.bg-color-3.classic {
  background-color: #c8c8e882;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.data-row.bg-color-3.modern {
  background-color: #043e8d;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.data-row.bg-color-3.classic {
  background-color: #c8c8e882;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.data-row.bg-color-3.modern {
  background-color: #043e8d;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.send-tac-btn {
  position: absolute;
  right: 0;
  top: 12px;
  height: 25px;
  width: 70px;
  font-size: 10px;
  color: white;
  background: transparent;
  border: 0;
  border-radius: 10px;
}

.send-tac-btn:disabled {
  opacity: 0.3;
  cursor: not-allowed;
}

.phoneNum-input-wrapper {
  position: relative;
}

.tac-countdown {
  margin-top: -10px;
  font-size: 11px;
  color: #ffc159;
}

.input-card {
  background: #0e3467;
  box-shadow: 1px 3px 5px 0 #0000006b;
  padding: 5px 15px;
  margin: 20px 0;
  display: flex;
  align-items: center;
}

.vertical-line {
  height: 20px;
  width: 1px;
}

.register-icon {
  width: 30px !important;
  height: 30px !important;
}

.register .input-card .form-group .invalid-feedback {
  padding-left: 0;
}

.register
  .input-card
  div#phoneContainer
  > div.separate-dial-code
  > input.customInput {
  padding: 0;
}

.register .input-card .flag-container .selected-flag .iti-flag {
  transform: scale(1.3);
  margin-right: 10px;
  margin-left: -20px;
}

.register-i8-icon {
  height: 51px;
  width: 75px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  margin-bottom: 20px;
}

.payment-option-card {
  background-color: #174f9c;
  color: #ffc159;
  /*    padding: 10px;*/
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 1px 5px 0 #0000006b;
}

.active .nav-item .payment-option-card {
  background-color: #0e3467;
  outline: 1px solid rgb(255 255 255 / 30%);
}

.active .nav-item .payment-option-card .s-card {
  background-color: #0e3467;
  outline: 1px solid rgb(255 255 255 / 30%);
}

.payment-option-card .options {
  display: flex;
  justify-content: space-evenly;
}

.payment-option-card .options .option {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding-top: 10px;
  padding-bottom: 5px;
}

.payment-option-card .options .option.active {
  box-shadow: 0px 2px 5px #00000094;
  border-radius: 10px;
  background-color: #005abe;
}

#deposit .payment-option-card .options .option.active {
  background-color: #ffcf7d;
}

.payment-option-card .options .option .option-image {
  background-color: #f0f8ff;
  color: #234c82;
  height: 60px;
  width: 60px;
  margin: 0 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  font-weight: bolder;
}

#deposit .payment-option-card .options .option .option-image {
  box-shadow: 0px 2px 2px #00000059;
}

.payment-option-card .options .option.active .option-text {
  color: #ffc159;
}

#deposit .payment-option-card .options .option .option-text {
  font-weight: 600;
}

#deposit .payment-option-card .options .option.active .option-text {
  color: #002e6c;
}

.payment-option-card .options .option .option-text {
  color: #ffffff;
  font-weight: lighter;
  margin-top: 5px;
}

.payment-option-card .normal-deposit .option-1 .option-image {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.payment-option-card .instant-deposit .option-2 .option-image {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.payment-option-card .instant-deposit .option-4 .option-image {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}

#deposit .active .nav-item .payment-option-card {
  background-color: #5c88bc;
  box-shadow: 0px 1px 5px 0 #0000006b;
}

#deposit .payment-option-card {
  background-color: #82b1e8;
  color: #fff;
  box-shadow: none;
}

.withdrawal-fullName-input {
  outline: none;
  border: none;
  border-bottom: 2px solid #002e6c;
  margin-bottom: 20px;
  font-size: 15px;
}

.react-simple-keyboard {
  margin-top: 10px;
}

.shareholder-my-income {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
}

.my-income-card {
  text-align: center;
  padding: 5px;
  border-radius: 15px;
}

.my-income-card-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.shareholder-my-income .content-title {
  font-weight: bold;
  font-size: 12px;
  color: #002e6c;
}

@media (max-width: 461px) {
  .shareholder-my-income .content-title {
    font-size: 10px !important;
  }
}

@media (max-width: 461px) {
  .shareholder-my-income .content-title {
    font-size: 10px !important;
  }
}

@media (max-width: 403px) {
  .shareholder-my-income .content-title {
    font-size: 8px !important;
  }
}

.shareholder-my-income .content-value {
  color: #002e6c;
  font-size: 15px;
}

.has-downline {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}

.offline-message {
  color: white;
  text-align: center;
  padding: 20px 60px;
}

.card-wrapper {
  padding: 3px;
  background: linear-gradient(
    100deg,
    #764e1b,
    #d7aa2d,
    #fffb9a,
    #d7aa2d,
    #fffb9a,
    #d7aa2d,
    #764e1b
  );
  border-radius: 15px;
}

.shareholder-my-income .card-wrapper {
  width: 30%;
}

.v2-um-tag {
  width: 100%;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  top: 0;
  left: 0;
}

.v2-um-tag.game-category-label {
  left: 0;
  top: 0;
  background-color: black;
  opacity: 0.8;
}

.v2-um-tag-icon {
  background-image: url(/static/media/dashboardv2_um.ec3690dd.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  /* top: 20px; */
  /* left: 15px; */
  width: 60px;
  height: 60px;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}

#rewards-homepage .banner {
  min-height: 150px;
}

#rewards-homepage .carousel-img {
  height: 220px;
  object-fit: cover;
}

@media (max-width: 490px) {
  #rewards-homepage .carousel-img {
    height: 170px;
  }
}

/* puishan working*/
@media screen and (max-width: 490px) and (min-width: 360px) {
  .modern-overlay {
    /*left: 26px !important;*/
  }

  .modern-clock-box > div > .modern-per-time-box {
    width: 22px !important;
    height: 35px !important;
  }

  .modern-per-time-digit {
    padding-top: 7px !important;
  }
}
/* end by puishan */

.rewards-calendar-day {
  display: flex;
  flex-direction: column;
  height: 60px;
  width: 40px;
  padding: 5px 0;
  border-radius: 10px;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

#rewards-homepage .rewards-value {
  height: 100%;
}

#rewards-homepage .rewards-value.left {
  background-color: rgb(2, 37, 84);
  text-align: center;
  font-size: 15px;
  width: 30%;
  padding: 25px 0;
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
}

#rewards-homepage .rewards-value.right {
  width: 70%;
}

#rewards-homepage .category-icon {
  width: 30px;
  margin-right: 50px;
}

.rewards-calendar {
  color: #002e6c;
  font-weight: bold;
  font-size: 16px;
}

.rewards-calendar-month {
  font-size: 18px;
}

.rewards-calendar-week {
  font-size: 12px;
  font-weight: normal;
}

.rewards-detail-banner {
  height: 205px;
  width: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
}

.rewards-detail-category {
  display: flex;
  justify-content: space-evenly;
  margin-top: -25px;
  z-index: 999;
}

.rewards-detail-category .category-icon-wrapper {
  background-color: #ffc159;
  text-align: center;
  padding: 8px;
  user-select: none;
  margin: 0 auto;
}

.rewards-detail-category .category-icon-wrapper .category-icon {
  width: 100%;
  height: 100%;
}

.rewards-detail {
  padding: 0 20px;
  padding-bottom: 50px;
}

.rewards-detail .category-name {
  text-align: center;
  color: white;
  font-size: 20px;
  font-weight: bold;
  margin: 20px 0;
  user-select: none;
}

.master-product-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 2fr;
  justify-items: center;
}

.master-product-grid .product-image {
  width: 100px;
  height: 100px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

@media (max-width: 341px) {
  .master-product-grid .product-image {
    width: 50px !important;
    height: 50px !important;
  }

  .master-product-grid .product-rate {
    font-size: 10px !important;
  }
}

@media (max-width: 476px) {
  .master-product-grid .product-image {
    width: 70px;
    height: 70px;
  }

  .master-product-grid .product-rate {
    font-size: 13px;
  }
}

.master-product-grid .product-rate {
  color: #002e6c;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.master-product-grid .card-wrapper {
  margin-bottom: 20px;
}

.referral-link-wrapper .form-control {
  padding-right: 50px;
}

.st-inline-share-buttons {
  width: 300px;
}

.downline-nav .nav-link {
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.settings-display-hp {
  border-radius: 20px;
  background-color: #aaaaaa;
  width: 100%;
  padding: 5px 10px;
}

.slick-slide {
  text-align: -webkit-center;
}
/*.slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;
}

.slick-initialized .slick-slide {
    display: block;
    margin: 0 10px;
    width: 55px !important;
}

.slick-track {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 200px !important;
}

.slick-slider .slick-list, .slick-slider .slick-track {
    -ms-transform: translateZ(0);
    transform: translateZ(0);
}

.slick-list {
    overflow: hidden;
    margin: 0;
    padding: 0;
}*/

.slick-list {
  border-radius: 10px;
}

.popup-bottom-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: calc(100% - 275px);
  overflow-y: scroll;
  padding-bottom: 30px;
}

.popup-bottom-wrapper::-webkit-scrollbar {
  display: none;
}

.tooltip-text {
  visibility: hidden;
  position: absolute;
  width: fit-content;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 10px;
  z-index: 1;
}

.refresh-password-btn:hover .tooltip-text {
  visibility: visible !important;
  right: 15px;
}

#game-page .user-info {
  padding: 0 10px;
  height: 50px;
  align-items: center;
}

#game-page .user-info-left {
  width: 50%;
}

#game-page .user-info-right {
  display: flex;
  align-self: center;
  width: 50%;
  justify-content: flex-end;
}

#game-page .wallet-and-commission {
  height: 60px;
}

#game-page .btn.btn-icon {
  font-size: 20px;
}

#game-page .btn.btn-icon:hover {
  background: linear-gradient(180deg, #ffcf7d, #fe9f00);
}

.bottom-drawer {
  display: flex;
  justify-content: center;
  background-color: #ffc159;
  cursor: move;
  user-select: none;
}

.top-drawer {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #ffc159;
  cursor: pointer;
}

.more-games {
  bottom: 0;
  left: 0;
  width: 100%;
  height: fit-content;
  max-height: 100vh;
  background-color: black;
  opacity: 0.9;
  z-index: 999;
  overflow-y: scroll;
}

.more-games::-webkit-scrollbar {
  display: none;
}

.more-games .lobby-games-img {
  width: 120px;
  height: 120px;
  position: relative;
}

.more-games .hot-games-tile {
  max-width: 120px;
}

.more-games .hot-games-name {
  padding: 0 5px;
  padding-bottom: 5px;
}

.more-games-content {
  display: flex;
  flex-direction: column;
  padding: 0 20px 10px 20px;
}

.more-games-content .hot-games-text {
  color: white;
  height: 30px;
  line-height: 14px;
}

.more-games .game-row {
  margin: 0;
}

.game-drawer-dark-bg {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: black;
  opacity: 0.5;
}

.game-tile-background {
  width: 120px;
  height: fit-content;
  top: 45px;
  border-radius: 13px;
  background-color: rgb(255 255 255 / 20%);
  backdrop-filter: blur(8px);
}

.more-games .currently-playing-label {
  width: 100%;
  position: absolute;
  top: 50px;
  font-size: 10px;
  background-color: black;
  color: #ffc159;
  padding: 7px 0;
}

.more-games .btn.btn-block.btn-brand {
  outline: none;
  border: none;
}

.home_page {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}

#rewards-homepage .accordion-button {
  width: 100%;
  display: flex;
  padding: 0;
  background: #03306d;
  border: none;
  border-radius: 15px;
  align-items: center;
  color: white;
}

#rewards-homepage .accordion-collapse {
  padding: 10px;
  background: #03306d;
  border-radius: 15px;
}

.dropdown-arrow {
  width: 10px;
  height: 10px;
  border-bottom: 3px solid rgb(255, 255, 255);
  border-right: 3px solid rgb(255, 255, 255);
  border-radius: 2px;
  transition: all 0.3s ease 0s;
  transform: rotate(-45deg);
}

.dropdown-arrow.active {
  transform: rotate(45deg);
}

.login-browser-icon.social-media {
  width: 20px;
  margin: 5px 13px 0 0;
}

.payment-option-card .instant-deposit .option-VaderPay .option-image {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
}

.payment-option-card .instant-deposit .option-PayTrust .option-image {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
}

.payment-option-card .normal-deposit .option-TnG .option-image {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
}

#downline-group-modern ul tr td {
  color: white;
}

.rewards-tnc {
  text-align: right;
  padding: 20px 25px;
  color: #ffc159;
  text-decoration: underline;
  cursor: pointer;
}

.rewards-tnc-modern {
  text-align: right;
  padding: 20px 25px;
  color: white;
  text-decoration: underline;
  cursor: pointer;
}

td.rdtDay.rdtDisabled {
  color: grey !important;
  text-decoration: line-through;
}

#downline-modern .tab-pane.active .ul-news-list,
#downline .tab-pane.active .ul-news-list {
  overflow-y: scroll;
  height: 70vh;
}

.date-filter-title {
  padding: 5px 20px;
  font-weight: bold;
}

.date-filter-title.modern {
  color: white;
}

.app-modern {
  background-color: #002e6c;
  min-height: 100vh;
  height: 100%;
  background-image: url(/static/media/Monogram_Background@2x.7edd5758.png);
  background-position: center top;
  background-attachment: fixed;
  background-repeat: repeat-y;
  background-size: cover;
}

.app-classic {
  min-height: 100vh;
  height: 100%;
  background-position: center bottom;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
}

/*here onwards start of modern-style css*/
.modern-header {
  background: transparent;
  /* background: #002e6c url(../img/Modern_NavigationBar.png);
        border-bottom: 1px solid #ffc159; */
  border-radius: 0 !important;
  position: relative;
}

#modern.transaction-nav .nav-link {
  color: white;
}

#modern.transaction-nav .nav-link.active {
  color: #ffc159;
}

#modern.transaction-nav .nav-link:not([href]):hover {
  color: white;
}

#modern.transaction-nav .nav-link.active:not([href]):hover {
  color: #ffc159;
}

#deposit-modern .form-group label {
  color: white;
}

#withdrawal-modern .form-group label {
  color: white;
}

#transfer-modern .form-group label {
  color: white;
}

#internal-transfer-modern .form-group label {
  color: white;
}

#deposit-modern .deposit-record-btn {
  font-size: 12px;
  color: white;
}

#withdrawal-modern .withdrawal-record-btn {
  color: white;
}

#deposit-modern .col-form-label {
  color: white;
}

#date-range-filter-modern .text {
  color: white;
}

#bonus-history-modern .col-form-label {
  color: white;
}

#game-history-modern .col-form-label {
  color: white;
}

.pool-result-modern .card-header {
  color: white;
}

.pool-result-modern tbody th,
.pool-result-modern thead th {
  color: white;
}

.pool-result-modern tbody td,
.pool-result-modern thead td {
  color: white;
}

p.f-w-800.text-brand.modern {
  color: white;
}

.downline-nav.modern .nav-item .nav-link {
  color: white;
}

.downline-nav.modern .nav-item .nav-link:hover {
  color: white;
}

.downline-games-slider-modern {
  margin-top: 10px;
}

.downline-games-modern.form-group label {
  color: white;
}

.downline-games-slider-modern .form-group label {
  color: white !important;
}

.downline-games-slider-modern .form-group .rangeslider .rangeslider__handle {
  background: #ffc159;
  color: #002e6c;
}

.downline-games-slider-modern .form-group .rangeslider .rangeslider__fill {
  background: #ffc159;
}

#referral-detail-modern .col-form-label {
  color: white;
}

.product-modern label {
  color: white !important;
}

.product-modern .form-group .rangeslider .rangeslider__handle {
  background: #ffc159;
  color: #002e6c;
}

.product-modern .form-group .rangeslider .rangeslider__fill {
  background: #ffc159;
}

.product-modern .form-group .rangeslider {
  background: #dbdbdb;
}

.table-kpi.modern tbody {
  color: white;
}

#comm-history-modern .comm-history-btn {
  color: white;
}

#downline-details-modern span {
  color: white;
}

#downline-details-modern .form-group label {
  color: white !important;
}

#downline-details-modern .form-group .rangeslider .rangeslider__handle {
  background: #ffc159;
  color: #002e6c;
}

#downline-details-modern .form-group .rangeslider .rangeslider__fill {
  background: #ffc159;
}

#downline-group-modern p {
  color: white;
}

#downline-group-modern .col-form-label {
  color: white !important;
}

#downline-group-modern .no-group-name {
  color: white;
}

#custom-lobby-modern .hot-games-label label {
  color: white;
}

#custom-lobby-modern .all-games .games-lobby .nav-item .nav-link {
  color: white;
}

#custom-lobby-modern .all-games .games-lobby .nav-item .nav-link.active {
  color: #ffc159;
}

#video-tutorial-modern .video-name {
  color: white;
}

.tnc-container.modern {
  color: white;
}

.tnc-container.modern::-webkit-scrollbar {
  background: #002e6c;
}

.tnc-container.modern::-webkit-scrollbar-thumb {
  background: #ffc159;
}

.tnc-container.modern .tnc-logo {
  background-color: transparent;
}

.tnc-container.modern .tnc-label {
  color: white;
}

.settings-page.modern .form-group label {
  color: white;
}

.settings-page.modern .settings-options-text {
  color: white;
}

.settings-page.modern .settings-radio-btn-label {
  color: white;
}

.settings-page.modern .change-pin-label {
  color: white;
}

.settings-page.modern input.pincode-input-text {
  border-bottom: 2px solid white !important;
}

.shareholder-nav.modern .nav-item .nav-link {
  color: white;
}

#shareholder-downline-modern .text-brand {
  color: white;
}

#shareholder-claim-modern .claim-history-btn {
  color: white !important;
}

#shareholder-detail-modern .form-group label {
  color: white !important;
}

#shareholder-detail-modern span.pl-4 {
  color: white;
}

#shareholder-detail-modern .form-group .rangeslider .rangeslider__handle {
  /* background: #ffc159;
  color: #002e6c; */
  background: linear-gradient(
    270deg,
    rgba(41, 213, 217, 0.9) 0%,
    rgba(129, 90, 238, 0.9) 97.78%
  );
  color: #fff !important;
}

#shareholder-detail-modern .form-group .rangeslider .rangeslider__fill {
  /* background: #ffc159; */
  background: linear-gradient(
    270deg,
    rgba(41, 213, 217, 0.9) 0%,
    rgba(129, 90, 238, 0.9) 97.78%
  );
}

.modal-content.edit-downline-modal.modern {
  background: #002e6c;
}

.modal-content.edit-downline-modal.modern .modal-header {
  color: white;
}

.edit-downline-modal.modern .close {
  color: white;
}

.modal-content.modern .modal-body::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: transparent;
  border-radius: 10px;
}

.modal-content.modern .modal-body::-webkit-scrollbar-thumb {
  background-color: #ffc159;
}

.modal-content.modern .modal-body::-webkit-scrollbar-corner {
  background: transparent;
}

#news-details-modern .news-detail-title {
  color: white;
}

#news-details-modern .text-brand {
  color: white;
}

.game-search-box {
  padding: 20px 61px;
  position: absolute;
  bottom: -14px;
  width: 100%;
}

#shareholder-downline-modern .font-thick {
  color: white;
}

#shareholder-downline-modern .dynamic-table-spinner {
  color: white;
}

#custom-lobby-modern .font-thick {
  color: white;
}

#custom-lobby-modern .dynamic-table-spinner {
  color: white;
}

#downline-modern .font-thick {
  color: white;
}

#downline-modern .dynamic-table-spinner {
  color: white;
}

#custom-lobby-modern input.form-control::placeholder {
  color: #6c757d;
}

#custom-lobby-modern .hot-games-text {
  color: white;
}

.wallet-info.modern {
  background-color: #002e6c;
}

.wallet-info.modern .modal-title {
  color: #ffc159;
}

.wallet-info.modern .modal-header .close {
  color: #ffc159;
}

#referral-detail-modern .row-data-max {
  background: white;
  color: #002e6c;
}

#edit-downline-modern .row-data-max {
  background: white;
  color: #002e6c;
}

.dashboard .banner {
  min-height: 150px;
}

.game-row tr {
  width: 100%;
}

#referral-detail-modern .data-row.category .row-data-max {
  background: rgb(255, 193, 89);
}

#referral-detail-modern .data-row.category .edit-percent-value {
  color: rgb(255, 193, 89);
}

#referral-detail-modern .data-row.category .row-data-max {
  background: rgb(255, 193, 89);
}

#referral-detail-modern .data-row.category .edit-percent-value {
  color: rgb(255, 193, 89);
}

#edit-downline-modern .data-row.category .row-data-max {
  background: rgb(255, 193, 89);
}

#edit-downline-modern .data-row.category .edit-percent-value {
  color: rgb(255, 193, 89);
}

.send-tac-btn {
  background-color: rgb(255, 193, 89);
  color: #002e6c;
  font-weight: 900;
}

#date-range-filter .date-selection-wrapper .selection-item.active,
#date-range-filter-modern .date-selection-wrapper .selection-item.active {
  color: #ed9500;
}

.popup-bottom-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100% - 225px);
}

/* Deposit payment method*/
.accordion-item {
  background: #4a73aa;
  border-radius: 10px;
  margin-bottom: 15px;
}

#rewards-homepage .accordion-item {
  background: none;
  border-radius: 15px;
}

.accordion-container .accordion-header .accordion-button {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: #f0f8ff;
  border-radius: 10px;
  border: 0;
  width: 100%;
  text-align: left;
  padding-left: 17px;
}

.accordion-container .accordion-header label {
  font-size: 16px;
  color: #0c2756 !important;
}

.accordion-container .accordion-header {
  margin-bottom: 12px;
}

.accordion-container .accordion-body .nav .nav-link {
  margin: 7px 0 14px;
  padding: 0;
}

.accordion-container .accordion-button:after {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f078";
  padding-right: 19px;
}

.accordion-container .accordion-button:not(.collapsed):after {
  content: "\f077";
}

/* NormalDeposit */
.deposit-form {
  margin-left: 20px;
  margin-right: 20px;
  padding-bottom: 20px;
}

.deposit-table .border-top {
  border-top-width: 0px !important;
}

.deposit-table .border-bottom {
  border-bottom-width: 0px !important;
}

.bank-text {
  color: #002e6c;
}

.float-right-icon {
  float: right;
}

.deposit-table .custom-checkbox {
  text-align: center;
}

.deposit-table .custom-checkbox input[type="radio"] {
  /* remove standard background appearance */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* create custom radiobutton appearance */
  display: inline-block;
  width: 15px;
  height: 15px;
  /* background-color only for content */
  background-clip: content-box;
  border: 2px solid #012c65;
  border-radius: 50%;
}

/* appearance for checked radiobutton */
.deposit-table .custom-checkbox input[type="radio"]:checked {
  background-color: #012c65;
  border: none;
}

.deposit-table {
  border-spacing: 0;
  border-collapse: separate;
  border: none !important;
  font-weight: bold;
}

.deposit-table td {
  padding-left: 20px !important;
  padding-right: 20px !important;
  border: 1px solid #00000029 !important;
}

.deposit-table td:nth-child(n + 1) {
  border-bottom-width: 0px !important;
  background-color: #ffffff;
}

.deposit-table tr:nth-last-child(1) td:nth-last-child(1),
tr:nth-last-child(1) td:first-child {
  border-bottom-width: 1px !important;
}

.deposit-table tr:last-child td:last-child {
  border-bottom-right-radius: 20px;
}

.deposit-table tr:last-child td:first-child {
  border-bottom-left-radius: 20px;
}

.deposit-table tr:first-child td:first-child {
  border-top-left-radius: 20px;
}

.deposit-table tr:nth-child(n + 1) td:first-child {
  border-right-width: 0px !important;
  background-color: #ffffff;
}

.deposit-table tr:first-child td:last-child {
  border-top-right-radius: 20px;
}

.custom-button {
  background: #d4e6ff;
  width: 90px;
  border: 1px solid #002e6c;
  color: #002e6c;
  font-weight: bold;
}

.amount-row {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 15px 0;
  text-align: center;
  padding: 13px 0px;
}

.custom-button:not(:disabled):not(.disabled):active {
  background-color: #adc4e4;
}

.deposit.input-group {
  box-shadow: none !important;
  background: #ffffff !important;
}

.close-button {
  padding: 10px;
}

.deposit-note {
  padding-left: 20px;
  padding-right: 20px;
}

.text-color-white {
  color: #fff;
  margin-top: 20px;
}

.second-row {
  background: none;
  border: none;
  box-shadow: none;
  padding: 0;
}

.option-image {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}

.s-card {
  background-color: #174f9c;
  color: #ffc159;
  padding: 10px;
  border-radius: 10px;
  align-items: center;
  box-shadow: 0px 1px 5px 0 #0000006b;
}

.text-color-blue {
  color: #002e6c;
  margin-top: 20px;
}

#deposit .accordion-item {
  background: #c2d8f6;
  border-radius: 10px;
  margin-bottom: 15px;
}

#deposit .custom-button {
  background: #fff;
}

#deposit-modern .offline-tng {
  background-color: #727272;
  opacity: 0.8;
}

.offline-icon {
  background-position: 50%;
  background-repeat: no-repeat;
  width: 60px;
  height: 60px;
}

.brand-nav .nav-item.active .setting-options-icon-bg {
  box-shadow: 3px 5px rgba(0, 0, 0, 0.2);
  background-color: #ffc159;
}

@media screen and (max-width: 475.9px) {
  .date-selection-wrapper {
    display: grid;
    grid-template-columns: 24% 24% 24% 24%;
    grid-column-gap: 0.2rem;
  }

  .date-selection-wrapper .selection-item {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 45px;
    white-space: normal;
  }
}

.vader-qr-logo {
  background-position: center center;
  background-repeat: no-repeat;
  height: 108px;
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 10px 0 0 0;
}

.spg-qr-logo {
  background-position: center center;
  background-repeat: no-repeat;
  height: 100px;
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 27px 0 0 0;
}

.fatty-qr-logo {
  background-position: center center;
  background-repeat: no-repeat;
  height: 100px;
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 27px 0 0 0;
}

.timer-background {
  background-position: center center;
  background-repeat: no-repeat;
  height: 150px;
  display: flex;
  justify-content: center;
  width: auto;
  background-size: contain;
}

.timer-box {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 4px;
}

.clock-box {
  font-family: "averta-black";
  color: #002e6c;
  font-size: 18px;
  justify-content: center;
}

.clock-box > div {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 1px;
  line-height: 0.7;
}

.clock-box > div > .per-time-box {
  background: rgba(19, 0, 41, 0.1);
  width: 32px;
  height: 47px;
  border-radius: 10px;
  padding: 17px 0px;
}

.sport-timer {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  top: unset;
  bottom: 8%;
}

.timer-text {
  color: #002e6c;
  margin-top: 5px;
  font-family: "averta-black";
  font-size: 10px;
  text-transform: capitalize;
}

.timer-diamond {
  padding-bottom: 1.5vh;
  width: 25px;
  line-height: 2vh;
}

.count-component {
  padding-left: 60px;
}

.img-100 {
  display: inline-block;
  width: 50%;
}

@media only screen and (max-width: 500px) {
  .timer-background {
    background-position: center center;
    background-repeat: no-repeat;
    height: 16vh;
    display: flex;
    justify-content: center;
    width: auto;
    background-size: contain;
  }

  .timer-box {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 1vw;
  }

  .clock-box {
    font-family: "averta-black";
    color: #002e6c;
    font-size: 4.3vw;
    justify-content: center;
  }

  .clock-box > div {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 1px;
    line-height: 0.7vh;
  }

  .clock-box > div > .per-time-box {
    background: rgba(19, 0, 41, 0.1);
    width: 7.5vw;
    height: 4.3vh;
    border-radius: 10px;
    padding: 17px 0px;
  }

  .sport-timer {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    top: unset;
    bottom: 8%;
  }

  .timer-text {
    color: #002e6c;
    margin-top: 5px;
    font-family: "averta-black";
    font-size: 3vw;
  }

  .timer-diamond {
    padding-bottom: 1vh;
    width: 2vw;
    line-height: 1.5vh;
  }

  .count-component {
    padding-left: 14vw;
  }

  .img-100 {
    display: inline-block;
    width: 2.5vw;
  }
}

/* puishan working*/
.modern-timer-section {
  position: relative;
  display: flex;
  flex-direction: column;
}

.modern-overlay {
  position: absolute;
  color: #f1f1f1;
  /*width: 100%;*/
  color: white;
  font-size: 20px;
  padding: 0 0 0 25px;
  text-align: center;
  /*left: 37px;*/
  right: 8px;
  top: 0;
  bottom: 0;
  left: 53%;
  transform: translateX(-50%);
}

.modern-timer-box {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 10px;
}

.modern-clock-box {
  font-family: "averta-black";
  font-weight: bold;
  font-size: 34px;
  color: #ffffff;
}

.modern-clock-box > div {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 2px;
  line-height: 1;
  padding-top: 5px;
  text-transform: capitalize;
}

.modern-clock-box > div > .modern-per-time-box {
  width: 32px;
  height: 45px;
  background: rgba(255, 255, 255, 0.2);
  background-repeat: no-repeat;
  background-origin: padding-box;
  border-radius: 7px;
  color: #ffffff;
}

.modern-timer-text {
  text-align: center;
  font-weight: bold;
  font-family: "averta-black";
  line-height: 10px;
  font-size: 8px;
  letter-spacing: 0px;
  color: #f5e9ff;
  opacity: 1;
}

.modern-timer-diamond {
  padding-bottom: 12px;
}

.modern-per-time-digit {
  color: #ffffff;
  font-size: 15px;
  padding-top: 13px;
  text-align: center;
  font-size: 15px;
  font-family: "averta-black";
  line-height: 19px;
  font-weight: bold;
  letter-spacing: 0px;
  color: #ffffff;
  text-shadow: 0px 7px 3px #0f002b33;
  opacity: 1;
}
/* end by puishan */

/*junwei working*/

.forgot-password-input {
  width: 80%;
  height: 30px;
  border: none;
  font-family: "averta-black";
  font-size: 11px;
  border-bottom: 3px solid #002e6c;
  background-color: white;
}

.forgot-password-input::placeholder {
  padding-left: 5%;
  color: #002e6c;
  font-size: 16px;
}

forgot-password-input :focus {
  border: none;
  outline: none;
  border: 0px solid #555;
}

forgot-password-modal-header ModalHeader {
  font-size: 20px;
}

.tac_send_again {
  text-align: center;
  width: 100%;
}

.tac_send_again button {
  color: #ffc159;
  font-family: "averta-semibold";
  font-size: 11px;
  border: none;
  background: none;
}

.forgot-password-second-input {
  position: relative;
  top: 30px;
}

.reset_password_success {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  align-items: center;
  justify-content: center;
  position: relative;
  bottom: 20px;
  height: 100%;
}

.reset_password_success span {
  width: 100%;
  padding-top: 20px;
  font-family: "averta-black";
  color: #002e6c;
  position: relative;
}

/*end by junwei*/

/*end of modern-style css*/

html,
body {
  background-color: transparent;
  color: #fff;
  font-family: "Poppins", sans-serif;
}

.all-section-box {
  height: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  flex-direction: column;
  /* background-color: #fff; */
  font-family: "Poppins", sans-serif;
  color: #fff;
  background: transparent !important;
  background-image: unset !important;
}

.all-section-box > div {
  width: 100%;
  
}

.welcome {
  height: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  flex-direction: column;
  background-color: white !important;
  /* background-image: url(../img/home/welcome-bg.png) !important; */
  background-image: var(--theme-img-bg-base) !important;
  background-size: cover !important;
  background-position: center top !important;
  background-repeat: no-repeat !important;
  font-family: "Poppins", sans-serif;
  color: #000;
  padding: 0px 5%;
}

.welcome h1 {
  background: -webkit-linear-gradient(
    270deg,
    rgba(41, 213, 217, 0.9) 0%,
    rgba(129, 90, 238, 0.9) 97.78%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.welcome > .first {
  margin-top: 7em;
}

.welcome > .last {
  margin-bottom: 1em;
}

.welcome > div {
  width: 100%;
  text-align: center;
}

.welcome .sub-title {
  /* color: #334155; */
  color: var(--theme-color-text-base);
}

.cover-90 {
  width: 90%;
  margin: 0px auto;
}

.welcome-gray-box {
  height: 393.22px;
  background: #d9d9d9;
  width: 100%;
  margin-top: 10px;
}

.welcome .home-header.shared-header .top-header i {
  color: var(--theme-color-text-base) !important;
}

.login {
  display: block;
  justify-content: unset;
  align-items: unset;
}

h1 {
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  color: #000000;
}

h2 {
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: #29d5d9;
}

h3 {
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  color: #fff;
}

h4 {
  font-weight: 700;
  font-size: 18px;
  line-height: 30px;
  color: #fff;
}

h5 {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
}

h6 {
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #fff;
}

.btn-custom {
  height: 48px !important;
  background: linear-gradient(
    270deg,
    rgba(41, 213, 217, 0.9) 0%,
    rgba(129, 90, 238, 0.9) 97.78%
  );
  border: 0px;
  border-radius: 8px;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 12px 20px !important;
  gap: 10px;
  font-weight: 700 !important;
  color: #fff !important;
}

.btn-custom.disabled {
  border: 1px solid #475569;
  color: #475569;
  background: #10182d;
  cursor: pointer;
  pointer-events: none !important;
}

.btn-custom-reverse {
  height: 48px !important;
  background: linear-gradient(
    270deg,
    rgba(129, 90, 238, 0.9) 0%,
    rgba(41, 213, 217, 0.9) 97.78%
  );
  border: 0px;
  border-radius: 8px;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 12px 20px !important;
  gap: 10px;
  font-weight: 700 !important;
  color: #fff !important;
}

.btn-custom-reverse.disabled {
  border: 1px solid #475569;
  color: #475569;
  background: #10182d;
  cursor: pointer;
  pointer-events: none !important;
}

.img-100 {
  width: 100%;
}

.width-100-p {
  width: 100%;
}

.font20 {
  font-size: 20px;
}

.font18 {
  font-size: 18px;
}

.font16 {
  font-size: 16px;
}

.font14 {
  font-size: 14px;
}

.font13 {
  font-size: 13px;
}

.font12 {
  font-size: 12px;
}

.font10 {
  font-size: 10px;
}

.f-weight-300 {
  font-weight: 300;
}

.f-weight-400 {
  font-weight: 400;
}

.f-weight-500 {
  font-weight: 500;
}

.f-weight-600 {
  font-weight: 600;
}

.f-weight-700 {
  font-weight: 700;
}

.bg-darkblue {
  background: #0d1426 !important;
  box-shadow: unset !important;
}

.bg-lblue {
  background: #1b284b !important;
}

.b-shadow {
  box-shadow: 0px 1px 3px 1px rgb(89 85 221 / 63%);
  -webkit-box-shadow: 0px 1px 3px 1px rgb(89 85 221 / 63%);
  -moz-box-shadow: 0px 1px 3px 1px rgba(89, 85, 221, 0.63);
}

.text-custom-lavender {
  color: #4d31e9;
}

.text-lblue {
  color: #29d5d9;
}

.text-lblue1 {
  color: #4297ff !important;
}

.text-dblue {
  color: #1e293b !important;
}

.text-dgray {
  color: #334155 !important;
}

.text-lgray {
  color: #475569;
}

.text-lgray2 {
  color: #94a3b8;
}

.text-lgray3 {
  color: #6f6f70;
}

.text-lgray4 {
  color: #cbd5e1;
}

.text-lgray5 {
  color: #cbd5e1;
}

.text-lgray6 {
  color: #64748b;
}

.text-lgray7 {
  color: #707070;
}

.text-customred {
  color: #ff4545;
}

.text-customred1 {
  color: #ff493f !important;
}

.text-greenlight {
  color: #30c952;
}

.text-customgreen {
  color: #6fcb20 !important;
}

.text-customyellow {
  color: #ffb600;
}

.text-redlight {
  color: #ff4015;
}

.border-0 {
  border: 0px;
}

.hr-separator {
  background: #343a40;
  width: 100%;
  margin-top: 25px;
  margin-bottom: 25px;
  height: 1px;
}

#login-custom-id {
  height: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: unset;
  flex-direction: column;
  background-color: #fff;
  font-family: "Poppins", sans-serif;
  color: #000;
}

.custom-form label {
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 6px;
  padding-left: 0px;
  color: #fff;
}

.custom-form label b {
  font-weight: 400;
}

.custom-form input {
  font-size: 16px;
  height: 45px;
  padding: 0px 14px;
  border: 0px;
  color: #ffffff !important;
  box-shadow: unset;
  width: 100%;
  outline: unset;
  background: #0d1426 !important;
  border: 1px solid #334155;
  border-radius: 8px !important;
}

.with-white-form .custom-form input {
  font-size: 16px !important;
  height: 45px !important;
  padding: 0px 14px !important;
  border: 0px;
  /* color: #475569 !important; */
  color: var(--theme-color-text-base);
  box-shadow: unset;
  width: 100%;
  outline: unset !important;
  /* background: #fff !important; */
  background: var(--theme-color-bg-search);
  /* border: 1px solid #cbd5e1 !important; */
  border-radius: 8px !important;
}

.custom-form input::-webkit-input-placeholder {
  /* Edge */
  color: #475569;
}

.custom-form input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #475569;
}

.custom-form input::placeholder {
  color: #475569;
}

.custom-form input:hover,
.custom-form input:focus,
.custom-form input:active {
  box-shadow: 0px 0px 0px 4px rgba(255, 255, 255, 0.08) !important;
  border: 1px solid #29d5d9;
}

.with-white-form .custom-form input::-webkit-input-placeholder {
  /* Edge */
  color: #94a3b8;
}

.with-white-form .custom-form input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #94a3b8;
}

.with-white-form .custom-form input::placeholder {
  color: #94a3b8;
}

.with-white-form .custom-form input:-webkit-autofill,
.with-white-form .custom-form input:-webkit-autofill:hover,
.with-white-form .custom-form input:-webkit-autofill:focus,
.with-white-form .custom-form input:-webkit-autofill:active {
  border: 1px solid #4d31e9 !important;
}

.with-white-form .custom-form input:hover,
.with-white-form .custom-form input:focus,
.with-white-form .custom-form input:active {
  border: 1px solid #4d31e9 !important;
}

.with-white-form .custom-form label {
  /* color: #334155; */
  color: var(--theme-color-text-base);
  font-weight: bold;
  font-size: 16px;
}

.custom-form .form-group label.form-checkbox-label {
  color: #000;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
}

.custom-form .form-group label.form-checkbox-label b {
  font-weight: unset !important;
}

.custom-form input.form-control.form-checkbox {
  margin-top: 0px;
}

.custom-checkbox {
  display: flex;
  align-items: center;
  column-gap: 10px;
  color: #4297ff !important;
  font-size: 14px;
}

.custom-checkbox input {
  display: none;
}

.custom-checkbox span {
  border: 2px solid #4297ff;
  border-radius: 1px;
  background: transparent;
  width: 18px;
  height: 18px;
}

.custom-checkbox input:checked ~ span {
  background: #4d31e9;
}

.custom-blue-checkbox span {
  border: 2px solid #29d5d9;
}

.custom-blue-checkbox input:checked ~ span {
  background-image: url(../img/new_icons/check.png) !important;
  background: transparent;
  background-size: auto;
  background-position: center;
  background-repeat: no-repeat;
}

.custom-blue-radio span {
  border: 2px solid #29d5d9;
  border-radius: 100px;
}

.custom-blue-radio input:checked ~ span {
  background-image: url(../img/new_icons/check.png) !important;
  background: transparent;
  background-size: auto;
  background-position: center;
  background-repeat: no-repeat;
}

#login-custom-id .btn-link {
  color: #000;
}

#login-custom-id .forgot-btn {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #4d31e9;
}

.custom-form input:-webkit-autofill,
.custom-form input:-webkit-autofill:hover,
.custom-form input:-webkit-autofill:focus,
.custom-form input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 1000px white inset !important;
  font-size: 16px;
}

.home-header.shared-header .top-header {
  /*color: #334155 !important;*/
  font-weight: 600;
  font-size: 14px;
  min-height: 28px;
}

.home-header.shared-header .top-header i {
  /*color: #334155 !important;*/
}

.home-header.shared-header .top-header .btn {
  /* padding: 0px; */
  width: unset;
  background-color: transparent;
  border-color: transparent;
}

.home-header.shared-header {
  width: 100%;
  padding: 10px 0px;
}

.home-header {
  background: transparent;
  background-image: unset !important;
}

.page-sidebar-fixed.page-container {
  background-color: #070a13 !important;
  background-image: url(../img/home/new/bg.png) !important;
  background-size: 100%;
  background-position: top center !important;
  background-repeat: no-repeat !important;
}

.scan-camera {
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  width: 128px;
  text-align: center;
  margin: auto;
}

.wallet-notice{
  color: #ffd800;
    font-size: 12px;
    display: flex;
    gap: 5px;
    align-items: center;
    margin-top: 10px;
}
.wallet-notice img{
  width: 25px;
}

.vip-icon-wallet{
  width: 25px;
  height: 25px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  
}
.box-active{
  border: 1px solid #29d5d9;
}
.fs-promotion-item{
  position: relative;
}

.fs-promotion-item.is-active:before {
  content: " ";
  position: absolute;
  left: 0;
  top: -5px;  
  background-image: url(../img/active-tag.png);
  background-position: center;
  background-size: contain;
  width:100%;
  height: 18%;
  background-repeat: no-repeat;
}
.wallet-info-icon{
  width: 13px;
  height: 13px;
  color: #fff;
  margin-left: 5px;
}
.wallet-tooltip .tooltip-inner{
  background: #36497e;
  text-align: left;
  font-size: 12px;
}
.wallet-tooltip .arrow::before{
  border-top-color: #36497e !important;
  border-bottom-color: #36497e !important

}
.img-responsive {
  max-width: 100%;
  display: inline-block;
}


.img-responsive.ineligible::before {
    content: " ";
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    /*background: rgba(0,0,0,.6);*/
    background:red;
    z-index: 10;
}

.unavailable-image {
    filter: grayscale(50%) brightness(63%);
    opacity: 0.8;
    /* pointer-events: none;  */
    /* To make the image unclickable */
}

.custom-or {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.custom-or .first,
.custom-or .last {
  width: 100%;
  border-bottom: 1px dashed #cbd5e1;
}

.custom-or .middle {
  padding: 0px 15px;
}

.custom-form input.form-control:hover,
.custom-form input.form-control:focus,
.custom-form select.form-control:hover,
.custom-form select.form-control:focus {
  box-shadow: unset;
  /* background: #0D1426;
    color: #475569;
    border-radius: 8px !important; */
}

::-webkit-calendar-picker-indicator {
  color: #fff;
  filter: invert(0.8);
}

.user-available {
  column-gap: 7px;
}

.user-available .first {
  width: 15px;
}

.custom-form select {
  font-size: 16px;
  height: 45px;
  padding: 0px 14px;
  border: 0px;
  color: #475569;
  padding-right: 5px;
  width: 100%;
  background: #0d1426;
  border: 1px solid #334155;
  border-radius: 8px !important;
  -webkit-appearance: none;
  position: relative;
  background-image: url(../img/new_icons/angle-down.png) !important;
  background-position: 95% center;
  background-repeat: no-repeat;
}

.custom-form select:after {
  content: " ";
  background-image: url(../img/new_icons/angle-down.png) !important;
  position: absolute;
  width: 12px;
  height: 8px;
  right: 0px;
}

.custom-form select:focus-visible {
  border: 1px solid #334155;
  outline: 0;
}

.contact-number-box {
  display: flex;
  column-gap: 5px;
}

.contact-number-box .second {
  width: 100%;
}

.custom-form .contact-number-box select {
  width: 80px;
  font-size: 16px;
  height: 45px;
  padding: 0px 14px;
  border: 0px;
  color: var(--theme-color-text-base) !important;
  padding-right: 5px;
  background: var(--theme-color-bg-custom-form-input);
  /* border: 1px solid #cbd5e1 !important; */
  border-radius: 8px !important;
  -webkit-appearance: none;
  position: relative;
  background-image: url(../img/new_icons/angle-down-blue.png) !important;
  background-position: 90% center;
  background-repeat: no-repeat;
}

.verify-form {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
}

#Verify-qr .keyboard.keyboard-numpad .hg-button {
  background: #131c34;
  box-shadow: unset;
  border-radius: 3px;
  margin: 0 auto;
  width: 100%;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px;
  font-size: 20px;
  /* backdrop-filter: blur(54.3656px); */
}

#Verify-qr.white-input-pin .keyboard.keyboard-numpad .hg-button {
  background: #fff;
}

#Verify-qr .react-simple-keyboard {
  position: absolute;
  bottom: 0px;
  padding-bottom: 40px;
  padding-top: 21px;
  width: 100%;
  background: #0b101e;
  backdrop-filter: blur(54.3656px);
  left: 0;
}

#Verify-qr.white-input-pin .react-simple-keyboard {
  background: #d1d3d9;
}

#Verify-qr .keyboard.keyboard-numpad .hg-row {
  grid-gap: 7px;
  max-width: 90%;
}

#Verify-qr input.pincode-input-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  color: #4a4a4a;
  font-size: 20px;
  height: 48px !important;
  width: 48px !important;
  margin: 0px !important;
  border-bottom: 1px solid #000000 !important;
  background: #131c34 !important;
  border: 1px solid #475569 !important;
  border-radius: 8px !important;
}

#Verify-qr input.pincode-input-text:hover {
  border: 1px solid #29d5d9 !important;
  box-shadow: 0px 0px 0px 4px rgba(255, 255, 255, 0.08) !important;
}

#Verify-qr input.pincode-input-text:active {
  border: 1px solid #29d5d9 !important;
  box-shadow: 0px 0px 0px 4px rgba(255, 255, 255, 0.08) !important;
}

#Verify-qr .pincode-input-container {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 10px;
}

#Verify-qr.white-input-pin input.pincode-input-text {
  background: #fff !important;
  border: 1px solid #cbd5e1 !important;
}

#Verify-qr.white-input-pin input.pincode-input-text:hover {
  border: 1px solid #4d31e9 !important;
  box-shadow: 0px 0px 0px 4px rgba(97, 69, 234, 0.12) !important;
}

#Verify-qr.white-input-pin input.pincode-input-text:active {
  border: 1px solid #4d31e9 !important;
  box-shadow: 0px 0px 0px 4px rgba(97, 69, 234, 0.12) !important;
}

#Create-account .margin-bottom-0 .form-group {
  margin-bottom: 5px;
}

#Create-password .margin-bottom-0 .form-group {
  margin-bottom: 5px;
}

.password-with-eye {
  position: relative;
}

.password-eye {
  position: absolute;
  right: 5px;
  width: 24px;
  top: 37px;
}

.circle {
  width: 161px;
  height: 161px;
  background: #d9d9d9;
  border-radius: 100px;
  display: inline-block;
}

.mh-circle-profile {
  width: 32px;
  height: 32px;
  background: #d9d9d9;
  border-radius: 100px;
}

.home-slider .slick-dots li {
  width: 12px;
}

.home-slider .slick-dots li button:before {
  font-size: 8px;
  color: #815aee;
}

.home-slider .slick-dots li button:focus:before,
.slick-dots li button:hover:before {
  opacity: 0.25;
}

.home-slider .slick-dots li.slick-active button:before {
  color: #815aee;
}

.home-slider .slick-dots {
  bottom: -24px;
}

.home-slider {
  margin-bottom: 30px;
}

.home-balance-com-box {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 10px;
}

  .home-balance-com-box > .hbc-box {
    width: 100%;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
    background: var(--theme-color-bg-custom-form-input);
    box-shadow: 0px 1px 3px 1px rgba(89, 85, 221, 0.63);
    -webkit-box-shadow: 0px 1px 3px 1px rgba(89, 85, 221, 0.63);
    -moz-box-shadow: 0px 1px 3px 1px rgba(89, 85, 221, 0.63);
  }

.home-top-menu {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.home-top-menu > div {
  width: 62px;
}

.home-game-category {
  display: flex;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  overflow-x: auto;
  padding: 2px;
  padding-bottom: 15px;
  margin-bottom: 5px;
}

.home-game-category li a {
  display: block;
  padding: 10px 14px;
  font-size: 13px;
  color: #94a3b8;
  background: #131c34;
  position: relative;
  border-radius: 12px;
  white-space: nowrap;
}

.home-game-category li.active a {
  color: rgba(41, 213, 217, 1);
  background: #2c4077;
}

.home-game-category li {
  position: relative;
}

.home-game-category li:before {
  content: " ";
  background: #334155;
  border-radius: 12px;
  position: absolute;
  top: -1px;
  right: -1px;
  left: -1px;
  bottom: -1px;
}

.home-game-category li.active:before {
  background: linear-gradient(
    to left,
    rgba(41, 213, 217, 0.9) 0%,
    rgba(129, 90, 238, 0.9) 97.78%
  );
}

.search-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 10px;
}

.search-with-icon-box {
  position: relative;
  width: 100%;
}

.search-with-icon-box .search-icon {
  width: 17px;
  position: absolute;
  left: 15px;
  top: 12px;
}

.search-with-icon-box input {
  background: #131c34;
  border-radius: 8px;
  border: 1px solid #1e293b !important;
  font-weight: 400;
  font-size: 14px;
  padding: 12px 16px 12px 45px;
  width: 100%;
  height: 45px;
  color: #64748b;
  outline: none;
}

.search-with-icon-box input::-webkit-input-placeholder {
  /* Edge */
  color: #64748b;
}

.search-with-icon-box input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #64748b;
}

.search-with-icon-box input::placeholder {
  color: #64748b;
}

.search-sort {
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  background: #131c34;
  border: 1px solid #1e293b;
  border-radius: 8px;
}

.home-service-provider {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  row-gap: 10px;
}

.home-service-provider .hsp-item {
  flex: 0 0 31.33333%;
  max-width: 31.33333%;
  position: relative;
}

.home-service-provider .hsp-item img {
  border-radius: 10px;
}

.home-service-provider .gamelist-name {
  text-align: center;
  font-weight: 500;
  background: linear-gradient(
    180deg,
    rgba(17, 4, 42, 0) 0%,
    rgba(16, 24, 45, 0.72) 68.75%,
    rgba(16, 24, 45, 0.8) 100%
  );
  border-radius: 0px 0px 8px 8px;
  position: absolute;
  bottom: 0px;
  width: 100%;
  padding: 38px 0px 12px;
  font-size: 12px;
  display: none;
}

.game-list-with-play-btn {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  row-gap: 10px;
}

.game-list-with-play-btn .game-p-item {
  flex: 0 0 48%;
  max-width: 48%;
  position: relative;
  height: 260px;
  border-radius: 9px;
}

.game-list-with-play-btn .gamelist-name {
  text-align: center;
  font-weight: 500;
  background: linear-gradient(
    180deg,
    rgba(17, 4, 42, 0) 0%,
    #11042a 58.33%,
    #11042a 100%
  );
  border-radius: 0px 0px 8px 8px;
  position: absolute;
  bottom: 0px;
  width: 100%;
  padding: 38px 0px 12px;
  font-size: 10px;
}

.game-list-with-play-btn .ply-btn {
  background: #1b284b;
  border-radius: 8px;
  padding: 10px 20px;
  margin: auto;
  display: inline-block;
  font-weight: 700;
  font-size: 16px;
  color: #29d5d9;
}

.top-title-right .home-header.shared-header .top-header > div {
  justify-content: space-between;
  width: 100%;
}

.top-title-right .home-header.shared-header .top-header {
  width: 100%;
}

.main-wallet-menu {
  display: flex;
  column-gap: 10px;
  text-align: center;
}

.main-wallet-menu > li {
  width: 100px;
}

.main-wallet-game-balance {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
  padding-bottom: 12px;
  border-bottom: 1px solid #979797;
}

a .hovertext {
  display: none;
}

a:hover hovertext {
  display: block;
}

.mwgb-title-box {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 15px;
}

.mwgb-title-box > div:first-child {
  width: 48px;
}

.payment-unavailable .main-wallet-game-balance {
  opacity: 0.4;
}

.reload-bank-list-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #1b284b;
  border-radius: 8px;
  padding: 12px 16px;
  margin-bottom: 10px;
}

.reload-bank-modal .close {
  display: none;
}

.reload-bank-modal.modal-brand.modal-bottom .modal-header {
  display: block;
  border-radius: 0px;
  padding: 15px 5%;
}

.reload-bank-modal.modal-brand.modal-bottom {
  border-radius: 16px 16px 0px 0px;
  min-height: 100%;
  max-height: 100%;
  max-width: 500px;
  /* left: unset; */
  width: 100%;
  left: 50%;
  transform: translate(-50%, 0);
  /*    overflow-y: scroll;*/
}

.modal-body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #6c7ca5;
}

.modal-body::-webkit-scrollbar {
  width: 2px;
  background-color: #6c7ca5;
}

.modal-body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
  background-color: #10182d;
}

.reload-bank-modal .home-header.shared-header {
  padding: 0px;
}

.reload-bank-modal .reload-bank-list-box {
  padding: 0px 0px 5px;
  background: transparent;
}

.reload-bank-modal .modal-body {
  height: 100%;
  max-height: unset;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.urr-reload-amount-box h1 {
  line-height: 1;
}

.urr-amount-box {
  background: #1b284b;
  border-radius: 16px;
  text-align: center;
  padding: 16px;
}

.urr-add-amount-box {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  grid-row-gap: 10px;
  row-gap: 10px;
}

.urr-add-amount-box > div {
  position: relative;
  flex: 0 0 31.33333%;
  max-width: 31.33333%;
}

.urr-add-amount-box > div:before {
  content: " ";
  background: #1e293b;
  border-radius: 34px;
  position: absolute;
  top: -1px;
  right: -1px;
  left: -1px;
  bottom: -1px;
}

.urr-add-amount-box > div.active:before {
  content: " ";
  background: linear-gradient(
    to left,
    rgba(41, 213, 217, 0.9) 0%,
    rgba(129, 90, 238, 0.9) 97.78%
  );
  border-radius: 34px;
  position: absolute;
  color: rgba(41, 213, 217, 1);
  top: -1px;
  right: -1px;
  left: -1px;
  bottom: -1px;
}

.urr-add-amount-box .urr-add-item {
  color: #64748b;
  background: #1e293b;
  border-radius: 24px;
  padding: 8px;
  text-align: center;
  position: relative;
}

.urr-add-amount-box .active .urr-add-item {
  color: rgba(41, 213, 217, 1);
}

.upload-file-box {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
  height: 80px;
  column-gap: 10px;
  background: #0d1426;
  border: 1px dashed #334155;
  border-radius: 8px;
  color: #29d5d9;
}

.upload-file-box .upload-img {
  width: 15px;
}

.ca-referrer-box {
  /* background: #ffffff; */
  background: var(--theme-color-home-game-link);
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.12);
  border-radius: 16px;
  padding: 16px 12px;
  text-align: center;
  margin-bottom: 35px;
}

.ca-referrer-box .font20 {
  color: #29d5d9;
  font-weight: 700;
}

.withdraw-balance-box {
  width: 100%;
  background: #16203c;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 12px;
  box-shadow: 0px 1px 3px 1px rgb(89 85 221 / 63%);
  -webkit-box-shadow: 0px 1px 3px 1px rgb(89 85 221 / 63%);
  -moz-box-shadow: 0px 1px 3px 1px rgba(89, 85, 221, 0.63);
}

.withdraw-balance-box .second {
  width: 20px;
}

.withdraw-amount-box {
  background: #fafafa;
  border-radius: 8px;
  padding: 14px 14px;
  margin-bottom: 12px;
}

.withdraw-amount-box > div {
  max-width: 145px;
}

.withdraw-amount-box .form-group {
  margin-bottom: 0px;
}

.withdraw-to-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #1b284b;
  border-radius: 8px;
  padding: 16px 16px;
  box-shadow: 0px 1px 3px 1px rgb(89 85 221 / 63%);
  -webkit-box-shadow: 0px 1px 3px 1px rgb(89 85 221 / 63%);
  -moz-box-shadow: 0px 1px 3px 1px rgba(89, 85, 221, 0.63);
}

.withdraw-amount-arrow-down {
  background: #6f6f70;
  border: 6px solid #ffffff;
  position: absolute;
  height: 48px;
  width: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  left: 50%;
  transform: translate(-50%, 0);
  bottom: -30px;
  z-index: 1;
}

.withdraw-amount-arrow-down img {
  width: 15px;
}

.withdraw-accounts-modal .reload-bank-list-box {
  background: #1b284b;
  padding: 12px 16px;
}

.reminder-with-icon {
  display: flex;
  align-items: center;
  grid-column-gap: 10px;
  column-gap: 10px;
  background: rgba(66, 151, 255, 0.12);
  border: 1px solid #4297ff;
  border-radius: 8px;
  padding: 12px 16px;
  color: rgba(255, 255, 255, 0.9);
}

.reminder-with-icon .first {
  min-width: 30px;
  text-align: center;
}

.reminder-red {
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-column-gap: 10px;
  column-gap: 10px;
  border-radius: 8px;
  padding: 12px 16px;
  color: rgba(255, 255, 255, 0.9);
  background-color: rgba(255, 73, 63, 0.12);
  border: 1px solid #ff493f;
}

.reminder-red > .first {
  display: flex;
  align-items: center;
  column-gap: 10px;
}

.reminder-red > .first > .first {
  min-width: 30px;
  text-align: center;
}

.reminder-red .font14 {
  color: #ff493f;
}

.reminder-green {
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-column-gap: 10px;
  column-gap: 10px;
  border-radius: 8px;
  padding: 12px 16px;
  color: rgba(255, 255, 255, 0.9);
  background: rgba(111, 203, 32, 0.12);
  border: 1px solid #6fcb20;
}

.reminder-green > .first {
  display: flex;
  align-items: center;
  column-gap: 10px;
}

.reminder-green > .first > .first {
  min-width: 30px;
  text-align: center;
}

.reminder-green .font14 {
  color: #6fcb20;
}

.reminder-green .second {
  color: #6fcb20;
}

.reminder-yellow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-column-gap: 10px;
  column-gap: 10px;
  border-radius: 8px;
  padding: 12px 16px;
  color: rgba(255, 255, 255, 0.9);
  background: rgba(111, 203, 32, 0.12);
  border: 1px solid #ffb600;
}

.reminder-yellow > .first {
  display: flex;
  align-items: center;
  column-gap: 10px;
}

.reminder-yellow > .first > .first {
  min-width: 30px;
  text-align: center;
}

.reminder-yellow .font14 {
  color: #ffb600;
}

.reminder-yellow .second {
  color: #ffb600;
}

.transaction-history-box {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 12px;
  padding-bottom: 12px;
  border-bottom: 1px solid #979797;
}

.thb-title-box {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-column-gap: 15px;
  column-gap: 15px;
}

#my-three-tabs {
  background: #1b284b;
  border-radius: 8.91px;
  overflow: hidden;
  padding: 2px;
}

#my-three-tabs.nav {
  justify-content: space-between;
}

#my-three-tabs li {
  width: 33%;
}

#my-three-tabs li a {
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  padding: 5px 5px;
  border: 0.5px solid transparent;
  color: #fff;
}

#my-three-tabs li .active {
  background: #2c4077;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.12), 0px 3px 1px rgba(0, 0, 0, 0.04);
  color: #29d5d9;
  border-radius: 6.93px;
}

#my-tabs {
  background: #1b284b;
  border-radius: 8.91px;
  overflow: hidden;
  padding: 2px;
}
#my-tabs li:only-child{
  width: 100%;

}
#my-tabs li {
  width: 50%;
}

#my-tabs li a {
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  padding: 5px 5px;
  border: 0px;
  color: #fff;
}

#my-tabs li .active {
  background: #2c4077;
  /* box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.12), 0px 3px 1px rgba(0, 0, 0, 0.04); */
  color: #29d5d9;
  border-radius: 6.93px;
}

.all-flex-box-gray {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #1b284b;
  border-radius: 8px;
  padding: 16px 16px;
}

.all-box-with-icon {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #1b284b;
  border-radius: 8px;
  padding: 16px 16px;
}

.all-box-with-icon > .first {
  display: flex;
  align-items: center;
  column-gap: 13px;
}

.reports-overview-list-box > .first > div:first-child {
  max-width: 19px;
  min-width: 19px;
}

.all-flex-box-gray-50 {
  display: flex;
  align-items: center;
  column-gap: 10px;
}

.all-flex-box-gray-50 > div {
  width: 50%;
  background: #131c34;
  border-radius: 8px;
  padding: 8px 12px;
}

.referral-edit-boxes {
  display: flex;
  flex-wrap: wrap;
  column-gap: 15px;
}

.referral-edit-boxes > div {
  flex: 0 0 40%;
  max-width: 40%;
}

.referral-edit-boxes > div:first-child {
  flex: 0 0 55%;
  max-width: 55%;
}

.referral-edit-boxes .first > div {
  background: #131c34;
  border-radius: 8px;
  padding: 8px 12px;
}

.referral-edit-boxes .second {
  background: #fafafa;
  border-radius: 8px;
  /* padding: 8px 12px; */
}

.transfer-to-box > div:first-child {
  width: 85%;
}

.transfer-to-box > div:first-child > div:first-child {
  max-width: 18px;
  min-width: 18px;
}

.form-group-margin-0 .form-group {
  margin: 0;
}

.nav.nav-tabs + .tab-content {
  padding: 0px;
  background: transparent;
}

.input-with-balance {
  background: transparent;
  border: 0px;
  outline: 0 !important;
  font-weight: 700;
  font-size: 32px;
  text-align: center;
  width: 100%;
  color: #29d5d9;
  padding: 0;
  height: 41px;
}

.input-with-balance::-webkit-input-placeholder {
  color: #29d5d9;
}

.earn-stat-box {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 12px;
}

.earn-stat-box > div {
  width: 50%;
  background: #131c34;
  padding: 8px 12px;
  border-radius: 8px;
}

.earn-stat-title {
  color: #cbd5e1;
  font-size: 14px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.earn-stat-title .first-title {
  width: 80%;
  line-height: 18px;
}

.earn-stat-title .earning-up {
  color: #6fcb20;
  font-size: 11px;
  column-gap: 2px;
  line-height: 1;
  margin-top: 3px;
  min-width: 26px;
}

.earn-stat-title .earning-down {
  color: #ff493f;
  font-size: 11px;
  column-gap: 2px;
  line-height: 1;
}

.earn-stat-number {
  color: #fff;
  font-size: 16px;
  font-weight: 600;
}

.earn-stat-menu {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 10px;
  /*border-bottom: 1px solid #1e293b;*/
  /*padding-bottom: 20px;*/
  margin-bottom: 20px;
}

.earn-stat-menu > a {
  width: 96px;
}

.earn-menu-title {
  max-width: 100px;
  margin-top: 5px;
  min-height: 30px;
}

.two-links-in-back-btn {
  display: flex;
  align-items: center;
}

.two-links-in-back-btn span {
  height: 10px;
  display: block;
  width: 1px;
  background: #fff;
  /* z-index: 1; */
  margin: 0px 10px;
}

.two-links-in-back-btn a {
  color: #fff;
}

.comm-bonus-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0px;
  border-bottom: 1px solid #1e293b;
  color: #94a3b8;
}

.comm-bonus-box > div:first-child {
  font-size: 12px;
}

.referral-code-box {
  background: #131c34;
  border-radius: 8px;
  padding: 16px 12px;
}

.p-m-add-comm-rates {
  display: flex;
  align-items: center;
  column-gap: 10px;
  border-bottom: 1px solid #334155;
  justify-content: space-between;
  padding: 10px 0px;
}

.p-m-add-comm-rates > div:first-child {
  display: flex;
  align-items: center;
  column-gap: 10px;
}

.p-m-add-comm-rates > div:first-child > div:first-child {
  width: 55px;
}

.add-comm-rates-box {
  background: #131c34;
  padding: 12px 12px 0px;
  border-bottom: 1px solid #334155;
}

.add-comm-rates-box.make-dark {
  background: #1b284b;
}

.p-m-max {
  white-space: nowrap;
}

.p-m-box {
  display: flex;
  column-gap: 10px;
  align-items: center;
  background: #2c4077;
  border-radius: 24px;
  padding: 6px;
}

.p-m-box input,
.p-m-box select {
  width: 50px;
  border: 0px;
  text-align: center;
  background: transparent;
  outline: unset;
  color: #fff;
}

.pm-max {
  font-size: 12px;
  font-weight: 700;
  background: linear-gradient(
    270deg,
    rgba(41, 213, 217, 0.9) 0%,
    rgba(129, 90, 238, 0.9) 97.78%
  );
  color: #fff;
  padding: 4px 8px;
  border-radius: 100px;
}

.add-comm-reset {
  display: flex;
  justify-content: flex-end;
}

.float-btn {
  background: #10182d;
  backdrop-filter: blur(1px);
  padding: 5%;
  position: fixed;
  z-index: 2;
  bottom: 0;
  width: 100%;
  left: 0;
}

.separator-span {
  height: 10px;
  display: block;
  width: 1px;
  background: #475569;
  /* z-index: 1; */
  margin: 0px 10px;
}

.add-remove-week-filter {
  display: flex;
  align-items: center;
  column-gap: 10px;
  border-bottom: 1px solid #1e293b;
  padding-bottom: 15px;
  margin-bottom: 15px;
  overflow-x: auto;
}

.add-remove-week-filter li {
  background: #131c34;
  border: 1px solid #334155;
  border-radius: 12px;
  padding: 10px 12px;
  color: #64748b;
  column-gap: 10px;
  font-size: 12px;
  display: flex;
  align-items: center;
  font-weight: 500;
  line-height: 1;
  height: 38px;
}

.add-remove-week-filter li > div:first-child {
  white-space: nowrap;
}

.add-remove-week-filter li > div:last-child {
  min-width: 16px;
}

.add-remove-week-filter li.active {
  background: #131c34;
  border: 1px solid #29d5d9;
  border-radius: 12px;
  padding: 10px 12px;
  color: #29d5d9;
  column-gap: 10px;
  font-size: 12px;
  display: flex;
  align-items: center;
  font-weight: 500;
  line-height: 1;
}

.add-remove-week-filter li img {
  width: 16px;
}

.accordion-clicker-box {
  display: flex;
  justify-content: space-between;
  background: #1b284b;
  border-radius: 8px;
  padding: 8px 16px;
  column-gap: 8px;
}

.accordion-clicker-box img{
  width: 35px;
}


.progress-bar-box {
  background: #060a12;
  border-radius: 100px;
  height: 7px;
  width: 80px;
  margin-bottom: 35px;
  overflow: hidden;
}

.progress-identifier {
  background: linear-gradient(
    270deg,
    rgba(41, 213, 217, 0.9) 0%,
    rgba(129, 90, 238, 0.9) 97.78%
  );
  border-radius: 100px;
  height: 100%;
  display: block;
}

.accordion-drop-icon {
  display: flex;
  align-items: center;
  grid-column-gap: 10px;
  column-gap: 10px;
}

.accordion-drop-icon img {
  width: 12px;
}


.accordion-box .img2 {
  display: none;
}
.accordion-drop-icon .amount {
  font-weight: bold;
  color: #39A7CA;
}
.accordion-drop-icon .coin {
  color: #FFD15B !important;
}
.accordion-box[aria-expanded="true"] .img1 {
  display: none;
}

.accordion-box[aria-expanded="true"] .img2 {
  display: block;
}

.accordion-box[aria-expanded="true"] .accordion-clicker-box {
  border-radius: 8px 8px 0px 0px;
  /* border-bottom: 1px solid #BFBFBF; */
}

.accordion-drop-box {
  background: #2c4077;
  box-shadow: 0px 2px 4px rgb(0 0 0 / 8%);
  border-radius: 0px 0px 8px 8px;
  padding: 16px;
}

.modal-content {
  background: #10182d;
}

.reports-stats-box {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 6% 4%;
}

.reports-stats-box > .ro-box-1 {
  width: 48%;
}

.reports-stats-box .ro-box-1-1 {
  background: #131c34;
  padding: 8px 12px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.reports-stats-box .ro-box-1-2 {
  background: #131c34;
  padding: 8px 12px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.reports-title-with-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.reports-title-with-btn > .second a {
  background: #131c34;
  border-radius: 4px;
  color: #29d5d9;
  padding: 4px 12px;
}

.report-final-balance-box {
  background: #131c34;
  padding: 8px 12px;
  border-radius: 8px;
}

.reports-overview-list-box {
  padding: 8px 16px;
  background: #1b284b;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.reports-overview-list-box > .first {
  display: flex;
  align-items: center;
  column-gap: 13px;
}

.wallet-statement-modal.modal-brand.modal-bottom {
  max-height: unset;
  min-height: unset;
}

.live-chat-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #1e293b;
  padding: 18px 0px;
}

.live-chat-box > div:first-child {
  display: flex;
  align-items: center;
  column-gap: 13px;
  color: var(--theme-color-text-primary);
}

.border-radius-100 {
  border-radius: 100px;
}

.swipeable-list-item__trailing-actions {
  display: flex;
  position: absolute;
  right: -10px;
  top: 0;
  justify-content: center;
  overflow: hidden;
  align-items: center;
  height: 89%;
  padding: 0px 20px;
  background: rgba(255, 73, 63, 0.12);
  border: 1px solid rgba(255, 73, 63, 0.4);
  border-radius: 8px;
}

.swipeable-list-item__content {
  position: relative;
  z-index: 1;
  background: #10182d;
  margin-bottom: 10px;
}

.swipe-delete {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.sweet-alert-container {
  color: #4a6386;
}

/*Start - for okup*/
/*Jason*/
#game-lobby-new {
  overflow-y: scroll;
}

#game-lobby-new::-webkit-scrollbar {
  display: none;
}

.input-with-balance::-webkit-inner-spin-button {
  display: none;
}

.home-game-category::-webkit-scrollbar {
  height: 5px;
  background: linear-gradient(
    270deg,
    rgba(41, 213, 217, 0.9) 0%,
    rgba(129, 90, 238, 0.9) 97.78%
  );
}

.home-game-category::-webkit-scrollbar-thumb {
  background: #29d5d9;
}

.home-service-provider {
  overflow-y: scroll;
}

.home-service-provider::-webkit-scrollbar {
  display: none;
}

.bottom-navigator-tab {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
}

.bottom-btn-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  width: 100px;
  padding: 10px 0;
}

.temp-footer {
  position: fixed;
  bottom: 0;
  background-color: #0f172a;
  width: 100%;
}

.temp-footer .bottom-btn-wrapper {
  padding: 10px;
  border-top: 1px solid white;
}

.temp-footer .bottom-btn-wrapper.active {
  background-color: #131d35 !important;
}
/*End - for okup*/
.live-chat-box .first img {
  width: 36px;
  height: 36px;
}

.commmison-history {
  background: #131c34;
  border-radius: 4px;
  color: #29d5d9;
  padding: 4px 12px;
}

.border-radius-10 {
  border-radius: 10px;
}
/*End - for okup*/

.withdrwal-header {
  display: flex;
  align-items: center;
}

.all-section-box {
  height: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  flex-direction: column;
  /* background-color: #fff; */
  font-family: "Poppins", sans-serif;
  color: #fff;
  background: transparent !important;
  background-image: unset !important;
  padding-bottom: 90px;
}

.withdraw-add-bank-success {
  min-height: 1% !important;
}

.page-container .content {
  overflow: hidden;
  overflow-y: scroll;
  position: unset !important;
}

.page-container .content::-webkit-scrollbar {
  display: none;
}
/* //media// */

/* //kpi custom// */
#kpi-select .css-16hr6bx-control {
  font-size: 16px;
  height: 53px;
  padding: 0px 16px;
  border: 0px;
  color: #29d5d9;
  padding-right: 16px;
  /* padding-right: 5px; */
  width: 100%;
  background: #1b284b;
  border: 1px solid #334155;
  border-radius: 8px !important;
  -webkit-appearance: none;
  position: relative;
  /* background-image: url(../img/new_icons/angle-down-blue.png) !important; */
  background-position: 95% center;
  background-repeat: no-repeat;
  box-shadow: 0px 1px 3px 1px rgb(89 85 221 / 63%);
  -webkit-box-shadow: 0px 1px 3px 1px rgb(89 85 221 / 63%);
  -moz-box-shadow: 0px 1px 3px 1px rgba(89, 85, 221, 0.63);
}

#kpi-select .css-16hr6bx-control div {
  padding: 0px;
}

#kpi-select .css-bbcqiw-control {
  font-size: 16px;
  height: 53px;
  padding: 0px 16px;
  border: 0px;
  color: #475569;
  padding-right: 16px;
  /* padding-right: 5px; */
  width: 100%;
  background: #0d1426;
  border: 1px solid #334155;
  border-radius: 8px !important;
  -webkit-appearance: none;
  position: relative;
  /* background-image: url(../img/new_icons/angle-down-blue.png) !important; */
  background-position: 95% center;
  background-repeat: no-repeat;
}

#kpi-select .css-bbcqiw-control div {
  padding: 0px;
}
/* #kpi-select .css-1hb7zxy-IndicatorsContainer{
    display: none;
} */
#kpi-select .css-2613-menu {
  background: #002c68 !important;
}

.kpi-total-box {
  display: flex;
  grid-column-gap: 12px;
  column-gap: 12px;
  align-items: center;
  justify-content: center;
  text-align: right;
}

.kpi-total-box > div {
  width: 100%;
  /* background: #131c34; */
  border-radius: 8px;
  overflow: hidden;
}

.kpi-total-box > div > .first {
  padding: 8px 12px;
  background: #131c34;
}

.kpi-total-box .total-font-green {
  font-size: 18px;
  color: #6fcb20;
  font-weight: 600;
}

.kpi-total-box > div > .second {
  padding: 8px 12px;
  background: #1b284b;
}

.kpi-total-box .total-font-white {
  color: #fff;
  font-size: 18px;
  font-weight: 600;
}

.kpi-history-btn .second {
  display: flex;
  align-items: center;
  column-gap: 7px;
}

.kpi-history-btn .commmison-history.active {
  background: #1b284b;
  box-shadow: 0px 1px 3px 1px rgb(89 85 221 / 63%);
  -webkit-box-shadow: 0px 1px 3px 1px rgb(89 85 221 / 63%);
  -moz-box-shadow: 0px 1px 3px 1px rgba(89, 85, 221, 0.63);
}

.game-filter {
  color: #64748b;
  margin-bottom: 20px;
}

.rdt .rdtPicker table thead tr th.rdtSwitch {
  color: #1f2225;
}

.data-hover {
  color: #29d5d9;
  cursor: pointer;
}

.tab-pane.referral-box {
  display: none;
}

.tab-pane.referral-box.active {
  display: block;
  margin: 0 0 100px;
}

.referral-qr-code {
  height: 100% !important;
  width: 100% !important;
  position: relative;
  border: 8px solid #fff !important;
}

.card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #1b284b;
  border-radius: 8px;
  padding: 16px 16px;
}

.text-brand {
  color: #fff;
}

.brand-link {
  color: #29d5d9;
}

.card-body {
  padding: 0px;
  width: 100%;
}

.p-m-box1 {
  display: flex;
  grid-column-gap: 0px;
  column-gap: 0px;
}

.p-m-box1 img {
  min-width: 20px;
}

.bonusComm-option-select {
  width: 70px;
  border: 0px;
  text-align: center;
  background: transparent;
  outline: unset;
  color: #fff;
  margin: 0px;
  padding: 0px 5px;
  height: unset;
}

.downline-detail h5 {
  color: #fff;
}

.downline-detail hr {
  background: #fff;
}

.downline-detail.modal-bottom .modal-body {
  padding: 15px !important;
}

.downline-detail td {
  color: #fff;
}

.downline-detail th {
  color: #fff;
}

.downline-detail.modal-bottom {
  border-radius: 16px 16px 0px 0px;
  min-height: 95%;
  max-height: 95%;
}

.downline-detail.modal-bottom .modal-header {
  padding: 15px;
}

.downline-detail .close {
  color: #fff;
  font-size: 30px;
  font-weight: 300;
}

.tab-content {
  width: 100%;
}

.spinner-wrapper {
  text-align: center;
  margin-top: 25px;
  margin-bottom: 20px;
}

.kpi-top-banner {
  padding: 0px 5%;
}

.kpi-body {
  background: #1b284b;
  padding: 0px 5%;
  margin-top: 95px;
  padding-top: 15px;
  padding-bottom: 90px;
}

#kpi-float > div {
  width: 43%;
}

#kpi-float {
  margin-top: -80px;
  display: flex;
  background: #1b284b;
  border-radius: 8px;
  padding: 16px 16px;
  flex-wrap: wrap;
  column-gap: 20px;
  justify-content: center;
}

.kpi-date {
  padding: 8px 0px;
  border: 1px solid #cccccc38;
  border-radius: 100px;
  text-align: center;
  color: #29d5d9;
  max-width: 47px;
  margin: auto;
}

.kpi-date span {
  width: 20px;
  display: inline-block;
  font-size: 9px;
}

.table-kpi-summary tr th {
  background: #10182d;
  font-weight: 400 !important;
  padding: 12px;
  border-bottom: 1px solid #334155 !important;
}

.table-kpi-summary.sales-comission tr th:first-child {
  background: #1b284b;
}

.table-kpi-summary tbody td,
.table-kpi-summary tfoot td {
  background: #131c34;
  border: 0px !important;
  border-bottom: 1px solid #334155 !important;
  font-weight: 500;
  color: #94a3b8;
  font-size: 14px;
}

.table-kpi-summary.sales-comission tbody td:first-child,
.table-kpi-summary.sales-comission tfoot td:first-child {
  background: #1b284b;
  width: 1%;
  white-space: nowrap;
}

#kpi-float .kpi-divider {
  width: 1px;
  /* height: 100%; */
  background: #fff;
}

.swiper-creative .swiper-slide {
  height: 215px;
}

.swiper-pagination-bullet {
  background: #475569;
}

.swiper-pagination-bullet-active {
  background: #815aee;
}

.swiper-img img {
  aspect-ratio: 2/1;
  border-radius: 8px;
}

.shareholder-edit-box {
  background: #131c34;
  border-radius: 8px;
  padding: 8px 12px;
}

.hbc-box.bg-lblue .second-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.hbc-box.bg-lblue .second-wrapper .second:first-child {
  /* margin-right: 6px; */
}

.hbc-box.bg-lblue .second {
  display: flex;
  align-items: center;
  justify-content: center;
}

.table-width {
  width: 100%;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 10px 20px 20px !important;
}

.icon {
  display: inline-block;
  width: 19px;
  height: 19px;
  background-size: cover;
}

.icon-title {
  font-size: 12px;
}

.svg-icon-home {
  background-image: url(../../assets/img/icon/bottom_tab/home.svg);
}

.svg-icon-home-active {
  background-image: url(../../assets/img/icon/bottom_tab/home-active.svg);
}

.svg-icon-activity {
  background-image: url(../../assets/img/icon/bottom_tab/activity.svg);
}

.svg-icon-activity-active {
  background-image: url(../../assets/img/icon/bottom_tab/activity-active.svg);
}

.svg-icon-wallets {
  background-image: url(../../assets/img/icon/bottom_tab/wallets.svg);
}

.svg-icon-wallets-active {
  background-image: url(../../assets/img/icon/bottom_tab/wallets-active.svg);
}

.svg-icon-downlines {
  background-image: url(../../assets/img/icon/bottom_tab/downlines.svg);
}

.svg-icon-downlines-active {
  background-image: url(../../assets/img/icon/bottom_tab/downlines-active.svg);
}

.svg-icon-account {
  background-image: url(../../assets/img/icon/bottom_tab/account.svg);
}

.svg-icon-account-active {
  background-image: url(../../assets/img/icon/bottom_tab/account-active.svg);
}

.disabled-action {
  color: #475569;
}

.earn-gen-description {
  /*    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 30px;*/
  border-bottom: 1px solid #1e293b;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.earn-gen-description-2 {
  /*    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 30px;*/
  border-top: 1px solid #1e293b;
  padding-bottom: 20px;
}

.earn-uptonow {
  padding-top: 20px;
}

.remove-border-bottom {
  border-bottom: none !important;
}

.vip-wrapper {
  padding: 1px;
  border-radius: 10px;
  overflow: hidden;
  color: #fff !important;
}
.vip-wrapper h4 {
  color: #fff !important;
}

.vip-1 {
  background: linear-gradient(to bottom, #845539, #bfa88f);
}

.vip-2 {
  background: linear-gradient(to bottom, #b44313, #eacda9);
}

.vip-3 {
  background: linear-gradient(to bottom, #808080, #f1f1f1);
}

.vip-4 {
  background: linear-gradient(to bottom, #e88e00, #fdf085);
}

.vip-5 {
  background: linear-gradient(to bottom, #689eb3, #e3edf0);
}

.vip-6 {
  background: linear-gradient(to bottom, #41a3ef, #e2f6fa);
}

.vip-text {
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
  text-align: center;
  font-size: 18px;
  margin-top: -8px;
  margin-bottom: -8px;
}

.vip-tab .flex-item {
  opacity: 0.4;
}

.vip-tab .flex-item.active {
  opacity: 1;
}

.vip-benefits-title {
  color: #29d5d9;
  font-size: 14px !important;
}

.vip-benefits-box {
  display: flex;
  /* align-items: center; */
  font-size: 11px;
  border-bottom: 1px solid #334155;
  flex-wrap: wrap;
}

.vip-benefits-box .first {
  flex: 0 0 calc(100% - 120px);
  background: #1b284b;
  padding: 12px;
  display: flex;
  align-items: center;
}

.vip-benefits-box .second {
  flex: 0 0 120px;
  background: #131c34;
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.vip-membership-box {
  background: linear-gradient(
    to right,
    rgba(86, 0, 1, 0.1),
    rgb(86, 0, 1) 10%,
    rgb(86, 0, 1) 90%,
    rgba(86, 0, 1, 0.1)
  );
  box-shadow: rgba(35, 23, 33, 0.8) 2px 0px 8px -4px,
    rgba(35, 23, 33, 0.8) -2px 0px 8px -4px;
  flex: 0 0 calc(100% - 120px);
  display: flex;
  align-items: center;
}

.vip-membership-box .first {
  flex: 0 0 calc(100% - 120px);
  display: flex;
  align-items: center;
  padding: 12px;
}

.vip-membership-box .second {
  flex: 0 0 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  border-left: 1px solid #ff5733;
}

.promotion-box {
  border-radius: 8px;
  overflow: hidden;
}

.promotion-content {
  background: transparent;
  padding: 15px 12px;
}

.promotion-content-flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}


.fs-promotion-item {
    width: calc(33.33% - 10px); /* Set the width for three items per row with some spacing */
    margin-bottom: 20px; /* Adjust as needed */
    margin-left:8px;
}

    .fs-promotion-item img {
        width: 100%;
        height: auto; /* Maintain aspect ratio */
        display: block;
    }

.promotion-btn-box {
  display: flex;
  column-gap: 10px !important;
}

.promotion-btn-box > div {
  width: 100%;
}

.promotion-time-container {
  display: flex;
  column-gap: 5px;
}

.promotion-inner-btn {
  align-items: center;
  justify-content: space-between;
  column-gap: unset !important;
}

.promotion-inner-btn > div {
  width: unset;
}

.promotion-inner-btn > div:first-child {
  width: 100%;
  margin-right: 15px;
}

.promotion-time-item {
  text-align: center;
}

.promotion-time {
  background: #005abe;
  width: 33px;
  font-size: 12px;
  padding: 5px 0px;
  border-radius: 5px;
}

.promotion-time-label {
  font-size: 11px;
}

.promotion-remaining-label {
  font-size: 11px;
}

.promotion-table {
  min-width: 500px;
  text-align: center;
}

.promotion-table thead td {
  font-size: 12px;
  padding: 8px;
  font-weight: bold;
  white-space: nowrap;
}

.promotion-table tbody td {
  font-size: 10px;
  padding: 8px;
}

.promotion-table tbody td:first-child {
  font-weight: bold;
}

.promotion-table tbody td:last-child {
  font-weight: bold;
}

.table-scroll {
  overflow-x: auto;
}

.ol {
  list-style-type: disc;
  padding-left: 25px;
}

.ol a {
  color: #29d5d9;
  font-weight: bold;
}

.applicable-table {
  min-width: unset;
  width: 100%;
}

.applicable-table tbody td:last-child {
  font-weight: unset;
}

.terms-table {
  min-width: unset;
  width: 100%;
}

.progress-bar-box-deposit-promotion {
  background: #060a12;
  border-radius: 100px;
  height: 7px;
  width: 100%;
  overflow: hidden;
}

.rollover-balance-box {
  background: #1b284b;
  border-radius: 16px;
  text-align: right;
  padding: 16px;
}

.date-picker-custom {
  justify-content: center;
  border-bottom: 0px !important;
}

.forgot-password-modal {
  border-radius: unset !important;
}

.justify-center {
  display: flex;
  justify-content: center;
}

.home-vip-progress {
  padding: 2px 0 0;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 100px;
  text-align: center;
  font-size: 12px;
  font-weight: normal;
  position: relative;
  margin-bottom: 3px;
  width: 70%;
  max-height: 15px;
  line-height: 9px;
  overflow: hidden;
}

.home-vip-progress-wrapper {
  display: flex;
  justify-content: center;
}

.home-vip-progress-wrapper > span {
  font-size: 12px;
  padding: 0 4px;
}

.home-vip-progress-wrapper > span:first-child {
  padding-left: 0;
}

.home-vip-progress-wrapper > span:last-child {
  padding-right: 0;
}

.home-vip-progress-content {
  display: flex;
  justify-content: center;
  color: #94a3b8;
  font-size: 13px;
}

.home-vip-progress-content .deposit span {
  font-weight: bold;
  color: #29d5d9;
}

.home-vip-progress .progress-line {
  content: " ";
  position: absolute;
  background: linear-gradient(
    270deg,
    rgba(41, 213, 217, 0.9) 0%,
    rgba(129, 90, 238, 0.9) 97.78%
  );
  height: 100%;
  border-radius: 100px;
  left: 0;
  top: 0;
}

.home-vip-progress span {
  position: relative;
  z-index: 1;
}

.home-user-account-container {
  display: flex;
  column-gap: 5px;
  align-items: center;
  padding: 12px 10px;
  border-radius: 10px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;
  background-color: #000000;
  min-height: 93px;
}

.home-user-account-emblem {
  width: 63px;
  height: 63px;
  background-repeat: no-repeat;
  background-size: contain;
}

.home-user-account-container h4 {
  line-height: 26px;
  min-height: 26px;
}

.home-user-account-container > div:last-child {
  width: 100%;
}

.home-announcement {
  display: flex;
  align-items: center;
  column-gap: 15px;
  min-height: 24px;
}

.home-announcement-icon {
  background-image: url(../img/home/new/announcement.png);
  height: 24px;
  width: 24px;
}

.home-balance-container-tab-header {
  display: flex;
  column-gap: 10px;
  color: #ffffff;
  font-size: 14px;
  line-height: 1;
  align-items: center;
  padding: 10px 10px 10px;
  position: relative;
  background: rgba(27, 40, 75, 0.5);
  border-radius: 10px 10px 0px 0px;
}

.home-balance-container-tab-header:nth-child(2),
.home-balance-container-tab-header:nth-child(3) {
  margin-left: 8px;
}
/* .home-balance-container-tab-header:hover{
  color: #29D5D9;
} */
.home-balance-container-tab-header.active {
  /* -webkit-box-shadow: inset 0 1px 4px #1DA1F2;
  -moz-box-shadow: inset 0 1px 4px #1DA1F2; */
  /* box-shadow: inset 0 1px 4px #1DA1F2; */
  /* box-shadow: inset 0 3px 4px -1px #29D5D9; */
  box-shadow: inset 0 7px 10px -7px #1da1f2;
  background: rgb(27, 40, 75);
}

.home-balance-container-tab-header.active:after {
  content: " ";
  position: absolute;
  height: 5px;
  width: calc(100% - 5px);
  background: #1b284b;
  bottom: 0px;
  left: 50%;
  transform: translate(-50%, 0);
  /* margin: 0px 5px; */
  display: none;
}

.home-balance-container-tabs {
  display: flex;
  /* border-bottom: 1px solid #29D5D9; */
  position: relative;
  border-top-right-radius: 10px;
}

.home-balance-eye {
  position: absolute;
  top: 4px;
  right: 8px;
}

.home-balance-container-tabs-items {
  background-color: #1b284b;
  padding: 10px 10px;
  border-radius: 0px 10px 10px 10px;
  background-image: url(../img/home/new/effect.png) !important;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom center;
  box-shadow: inset 0 -7px 10px -7px #1da1f2;
}

.home-balance-input {
  color: #fff;
  background: transparent;
  outline: unset;
  border: 0px;
  font-size: 18px !important;
  font-weight: bold !important;
  /*height: 24.5px !important;*/
  width: 100%;
}

.home-balance-top-box > .first {
  display: flex;
  align-items: center;
  width: 100%;
}

.home-balance-top-box > .first > .first {
  width: 40px;
  border-right: 1px solid #343283;
  display: flex;
  align-items: center;
  padding-left: 8px;
  margin-right: 10px;
  height: 25px;
}

.home-balance-top-box > .first > .second {
  width: 100%;
}

.home-balance-container {
  background: #05102f;
  border-radius: 8px;
  padding: 5px 10px;
}

.home-balance-top-box {
  display: flex;
  align-items: center;
}

.home-balance-hr {
  background: #343283;
  margin: 5px 0px;
}

.home-daily-info-container {
  background: #13275c;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  padding: 5px 10px;
}

.home-daily-item {
  display: flex;
  align-items: center;
}

.home-daily-item > div {
  display: flex;
  align-items: center;
  width: 100%;
  column-gap: 3px;
}

.home-daily-item .daily-mid-hr {
  height: 10px;
  width: 1px;
  background: #343283;
  display: flex;
  margin: 0px 8px;
}

.home-daily-item > div > .first {
  color: #94a3b8;
  font-size: 7px;
  white-space: nowrap;
}

.home-daily-item > div > .second {
  color: #29d5d9;
  font-size: 8px;
  width: 100%;
  text-align: center;
}

.home-balance-commission-buttons {
  display: flex;
  column-gap: 8px;
}

.home-balance-commission-buttons > div {
  width: 25%;
}

.home-bc-item {
  background: linear-gradient(
    to bottom,
    rgba(119, 217, 134, 0),
    rgba(89, 85, 221, 0.5)
  );
  border-radius: 8px;
  padding: 1px 1px 1px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}

.home-bc-item-wrapper {
  background: #1b284b;
  text-align: center;
  -webkit-box-shadow: inset 0 2px 4px 0 rgba(29, 161, 242, 0.8);
  -moz-box-shadow: inset 0 2px 4px 0 rgba(29, 161, 242, 0.8);
  box-shadow: inset 0 2px 4px 0 rgba(29, 161, 242, 0.8);
  padding: 0 0 4px;
  border-radius: 8px;
  height: 100%;
}

.home-bc-item .first {
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.home-bc-item .second {
  color: #29d5d9;
  font-size: 12px;
}

.home-banner-slick {
  min-height: 144px;
}

.home-banner-slick .slick-dots {
  width: auto;
  background: #16203c;
  height: unset;
  border-radius: 100px;
  position: absolute;
  line-height: 0;
  display: flex !important;
  align-items: center;
  bottom: -10px;
  left: 50%;
  transform: translate(-50%, 0);
  display: inline-block;
  margin: auto;
}

.home-banner-slick .slick-dots li button:before {
  display: none;
}

.home-banner-slick .slick-dots li {
  width: unset;
  height: unset;
  margin: unset;
  line-height: 0;
  margin-top: -1px;
  display: block;
}

.home-banner-slick .slick-dots li button {
  background: transparent;
  padding: 0px;
  border-radius: 5px;
  width: 30px;
  height: 6px;
  line-height: 0;
}

.home-banner-slick .slick-dots li.slick-active button {
  background: #343183;
  padding: 0px;
  border-radius: 5px;
  width: 30px;
  height: 6px;
}

.home-promo-vip-container {
  display: flex;
  align-items: center;
  column-gap: 15px;
  margin-top: 40px;
  min-height: 182px;
}

.home-promo-vip-container > div {
  width: 50%;
  position: relative;
}

.home-promo-vip-container img {
  width: 100%;
  min-height: 180px;
  border-radius: 10px;
}

.home-promo-vip-container > div:before {
  content: " ";
  height: 135px;
  width: 100%;
  background: linear-gradient(
    180deg,
    rgba(17, 4, 42, 0) 0%,
    #11042a 58.33%,
    #11042a 100%
  );
  position: absolute;
  bottom: 0px;
  border-radius: 0px 0px 8px 8px;
}

.home-promo-vip-container a {
  background: #1b284b;
  box-shadow: inset 0px 1px 4px rgba(29, 161, 242, 0.8);
  border-radius: 8px;
  padding: 12px 0px;
  color: #29d5d9;
  font-size: 16px;
  font-weight: 700;
  display: block;
  text-align: center;
}

.home-promo-and-vip-label {
  font-size: 10px;
  color: #fff;
  font-weight: 700;
  text-align: center;
  margin-bottom: 8px;
}

.home-promo-and-vip-details {
  padding: 0px 10px;
  position: absolute;
  width: 100%;
  bottom: 10px;
}

.home-popular-section .home-game-category li.active a {
  background: #131c34;
}

.announcementRead {
  background-color: transparent !important;
}

.th-date-search {
  font-family: "averta-semibold";
  color: #fff;
  background: transparent;
  border: 0px;
  box-shadow: unset;
  justify-content: space-between;
  width: 100%;
}

.date-box .react-datepicker-wrapper {
  width: 100%;
}

.date-box {
  display: flex;
  column-gap: 5px;
}

.date-box .second {
  width: 100%;
}

.custom-form .date-box select {
  width: 88px;
  font-size: 10px;
  height: 30px;
  padding: 0px 14px;
  border: 0px;
  color: #475569 !important;
  padding-right: 5px;
  background: #fff;
  border: 1px solid #cbd5e1 !important;
  border-radius: 8px !important;
  -webkit-appearance: none;
  position: relative;
  background-image: url(../img/new_icons/angle-down-blue.png) !important;
  background-position: 90% center;
  background-repeat: no-repeat;
}

.react-datepicker__year-dropdown {
  height: 100px !important;
  overflow-y: scroll;
}

.date-picker-wrapper .react-datepicker__year-dropdown-container,
.date-picker-wrapper .react-datepicker__month-dropdown-container {
  height: 100px !important; /* adjust the height as needed */
  overflow-y: scroll;
}

.date-picker-wrapper .react-datepicker__year-dropdown,
.date-picker-wrapper .react-datepicker__month-dropdown {
  height: 100px !important;
}

.default-lobby {
  background: #10182d;
  border-radius: 16px 16px 0 0;
}

.default-lobby-modal {
  width: 90%;
  margin: 0 auto;
  margin-bottom: 50px;
}

.default-lobby-modal .default-lobby-body {
  padding: 10px 0;
}

.default-lobby-modal .home-header.shared-header {
  padding: 0;
}

.app-login-cred-text-box {
  outline: none;
  border: none;
  background: none;
  color: white;
  width: 50%;
}

.sweet-alert-custom-text {
  color: white;
  position: relative;
  bottom: 10px;
}

.sweet-alert-custom-background {
  background-color: #05102f;
  opacity: 0.9;
}

.custom-form .readonly:hover,
.custom-form .readonly:focus,
.custom-form .readonly:active {
  box-shadow: none !important;
  border: 1px solid #334155;
}

.custom-form .readonly {
  color: #475569 !important;
}

.comm-info-box {
  font-size: 11px;
  border-bottom: 1px solid #334155;
}

.comm-info-box .first {
  background: #1b284b;
  padding: 12px;
  width: 100%;
}

.comm-info-box .second {
  background: #131c34;
  padding: 12px;
  text-align: center;
}

.version-number {
  text-align: right !important;
  font-size: 13px;
}

.sweet-alert > div div {
  background: none !important;
}

.daily-check-in-container-items {
  background-color: #131c34;
  padding: 10px;
  border-radius: 8px;
}

.daily-check-in {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.daily-check-in > div {
  width: 25%;
  margin: 10px;
}

.daily-check-in-wrapper {
  text-align: center;
  height: 95px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.daily-check-in-wrapper .first {
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.daily-check-in-wrapper .daily-check-in-day-label {
  position: absolute;
  right: 2px;
  bottom: 0;
  left: 2px;
  color: #ffffffe5;
  border-radius: 0 0 8px 8px;
  margin: auto;
  font-size: 10px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0px;
  height: 26px;
}

.daily-check-in-items {
  position: relative;
  text-align: center;
}

.daily-check-in-middle-icon {
  border-radius: 8px;
}

.daily-check-in-top-icon {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  top: -10px;
  z-index: 3;
}

.day-icon,
.day-index {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  z-index: 1;
  color: #fff;
}

.day-icon {
  width: 24px;
  height: 24px;
  top: 0;
}

.day-index {
  font-size: 10px;
  font-weight: 700;
  line-height: 16px;
  text-align: center;
  top: 5px;
}

.daily-check-in-assets-icon img {
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -25%);
}

.daily-checkin-inactive {
  background: rgba(0, 0, 0, 0.6);
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  border: 1px solid;
  border-image-source: linear-gradient(
    180deg,
    #475f7b 0%,
    rgba(203, 213, 225, 0) 100%
  );
  z-index: 2;
}

.animated-border-box.white-border:before,
.animated-border-box-glow.white-border:before {
  background-image: linear-gradient(
    180deg,
    #cbd5e1 0%,
    rgba(203, 213, 225, 0) 100%
  );
}

.animated-border-box.yellow-border:before,
.animated-border-box-glow.yellow-border:before {
  background-image: linear-gradient(180deg, #ffb600 0%, #ffb600 100%);
}

.animated-border-box.color-border:before,
.animated-border-box-glow.color-border:before {
  background-image: linear-gradient(180deg, #ff91ba 0%, #4d31e9 100%);
}

.animated-border-box.white-border.animate:before,
.animated-border-box-glow.white-border.animate:before {
  background-image: conic-gradient(
    rgba(203, 213, 225, 0),
    rgb(111, 203, 32),
    rgba(203, 213, 225, 0) 25%
  );
}

.animated-border-box.yellow-border.animate:before,
.animated-border-box-glow.yellow-border.animate:before {
  background-image: conic-gradient(
    rgba(203, 213, 225, 0),
    #ffb600,
    rgba(203, 213, 225, 0) 25%
  );
}

.animated-border-box.color-border.animate:before,
.animated-border-box-glow.color-border.animate:before {
  background-image: conic-gradient(#ff91ba, #4d31e9, rgba(77, 49, 233, 0) 25%);
}

.game-points-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #1b284b;
  border-radius: 8px;
  padding: 8px 16px;
}

.game-points-container.section1 {
  padding: 10px 15px;
}

.game-points-container .details {
  font-size: 14px;
  color: #fff;
  font-weight: bold;
}

.game-points-container .first {
  display: flex;
  align-items: center;
  column-gap: 15px;
}

.game-points-container .custom-icon-box {
  padding: 8px 5px;
}

.game-points-container .second {
  display: flex;
  margin-left: 10px;
  background: #3369ff;
  border-radius: 20px;
  padding: 8px 25px;
  font-size: 12px;
  align-items: center;
  line-height: 1;
  color: #fff;
  border: unset;
}

.game-points-container .second:disabled {
  background: dimgrey;
}

.game-pts-item-card {
  border-radius: 10px;
  /* height: 250px; */
  background-color: #0d1a3b;
  box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3),
    inset 0px 1.5px 0.5px rgba(255, 255, 255, 0.1);
  background: linear-gradient(
    180deg,
    rgba(51, 105, 255, 0.24) -6.16%,
    rgba(27, 42, 84, 0.24) 110.96%
  );
  padding: 10px;
  height: 220px;
}

.game-pts-item-card .game-pts-item-img {
  border-radius: 10px;
  width: 125px;
  height: 125px;
}

.game-pts-item-card .game-pts-item-card-body {
  padding: 0px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 10px;
  font-size: 12px;
}

.game-pts-item-card .game-pts-item-card-body .game-pts-item-title {
  margin-bottom: 5px;
}

.game-pts-item-card .game-pts-item-card-body .game-pts-item-text {
  color: #29d5d9;
  font-weight: bold;
}

.game-pts-item-card .game-pts-item-img .game-pts-btn-disabled {
  width: 100%;
  border-radius: 10px;
  cursor: not-allowed;
}

.claim-game-point-item {
  padding: 10px 20px;
}

.claim-game-point-item .game-point-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.claim-game-point-item .game-point-item .first {
  font-size: 14px;
}

.claim-game-point-item .game-point-item .date-text {
  font-size: 10px;
}

.claim-game-point-item .red-label-box {
  font-size: 9px;
  background: #ff334b;
  border-radius: 20px;
  padding: 3px;
  color: #fff;
  width: 70px;
  text-align: center;
}

.claim-game-point-item .green-label-box {
  font-size: 9px;
  background: linear-gradient(
      0deg,
      rgba(164, 164, 164, 0.2),
      rgba(164, 164, 164, 0.2)
    ),
    linear-gradient(
      318.37deg,
      rgba(50, 249, 0, 0.8) 0%,
      rgba(20, 239, 199, 0.8) 94.77%
    );
  border-radius: 20px;
  padding: 3px;
  color: #fff;
  width: 70px;
  text-align: center;
}

.redeem-modal-image {
  display: flex;
  align-items: center;
  justify-content: center;
}

.redeem-content {
  padding: 30px 20px;
}

.redeem-content .title {
  font-size: 20px;
  line-height: 25px;
  color: #fff;
}

.redeem-content .points {
  display: flex;
  align-items: center;
  column-gap: 15px;
}

.redeem-content .details {
  color: #a4a4a4;
  font-size: 12px;
}

.redeem-content .redeem-tc a {
  color: #3369ff;
  font-size: 12px;
}

.daily-check-in-day-pts {
  position: absolute;
  width: calc(100% - 20px);
  height: 15px;
  bottom: 23%;
  left: 50%;
  transform: translate(-50%, -25%);
  border-radius: 6px 6px 0px 0px;
  padding: 0 4px;
  background: #ffffff;
}

.daily-check-in-day-pts span {
  position: absolute;
  width: 100%;
  top: 3px;
  left: 50%;
  transform: translateX(-50%);
  margin: auto;
  text-align: center;
  font-size: 8px;
  line-height: 12px;
  font-weight: 700;
  text-transform: uppercase;
  background: linear-gradient(
    270deg,
    rgba(77, 49, 233, 0.9) 0%,
    rgba(255, 57, 128, 0.9) 97.78%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

body .daterangepicker {
  z-index: 9999;
  left: 10% !important;
}

.slick-slide img {
  border-radius: 10px !important;
}

.qr-wrpper .referral-qr-code {
  width: 60% !important;
  height: 60% !important;
}

.redeem-modal-image {
  background-size: cover;
  width: 200px;
  height: 200px;
  background-position: center;
  border-radius: 10px;
  margin: auto;
}

.game-pts-wrapper {
  background-size: cover;
  width: 160px;
  height: 160px;
  background-position: center;
  border-radius: 10px;
}

#promotionMoreInfoEventDetails table {
  width: 100% !important;
}

#promotionMoreInfoEventDetails table span {
  font-size: 10px !important;
}

@media screen and (max-width: 390px) {
  .game-pts-wrapper {
    width: 140px;
    height: 140px;
  }
}

@media screen and (max-width: 340px) {
  .game-pts-wrapper {
    width: 125px;
    height: 125px;
  }
}

body {
  padding-right: 0px !important;
}

.daily-check-in-active {
  position: absolute;
}

.daily-check-in-label {
  height: 100%;
  width: 100%;
  z-index: 0;
  position: relative;
  overflow: hidden;
}

.daily-check-in-label span {
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  display: inline-block;
}

/* Shine */
.daily-check-in-label.shine::before {
  background: rgba(255, 255, 255, 0.13);
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0.13) 0%,
    rgba(255, 255, 255, 0.13) 77%,
    rgba(255, 255, 255, 0.5) 92%,
    rgba(255, 255, 255, 0) 100%
  );
  content: "";
  display: block;
  height: 100%;
  left: -100%;
  position: absolute;
  top: 0;
  transform: skewX(-25deg);
  width: 50%;
  z-index: 2;
}

.daily-check-in-label.shine::before {
  animation: shine 0.85s ease-in-out infinite;
}

/*// Glow Border Animation //*/
.animated-border-box,
.animated-border-box-glow {
  max-height: 200px;
  max-width: 250px;
  height: 100%;
  width: 100%;
  position: absolute;
  overflow: hidden;
  z-index: 0;
  border-radius: 10px;
}

.animated-border-box-glow.animate {
  overflow: hidden;
  filter: blur(20px);
}

.animated-border-box:before,
.animated-border-box-glow:before {
  content: "";
  z-index: -16;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(0deg);
  position: absolute;
  width: 99999px;
  height: 99999px;
  background-repeat: no-repeat;
  background-position: 0 0;
  /* background-image: conic-gradient(rgba(0,0,0,0), #1976ed, rgba(0,0,0,0) 25%); */
  /* animation: rotate 4s linear infinite; */
}

.animate:before {
  animation: rotate 4s linear infinite;
}

.animated-border-box:after {
  content: "";
  position: absolute;
  z-index: -1;
  left: 2px;
  top: 2px;
  width: calc(100% - 4px);
  height: calc(100% - 4px);
  background: #1f2e56;
  border-radius: 7px;
}

.center-box {
  height: 95px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #1f2e56;
}

@keyframes rotate {
  100% {
    transform: translate(-50%, -50%) rotate(1turn);
  }
}

@keyframes shine {
  0% {
    left: -100%;
    transition-property: left;
  }

  100% {
    left: 100%;
    transition-property: left;
  }
}

.w-20 {
  width: 20px;
}

.marquee-container {
  width: 100%;
}

.modal-dc {
  position: relative !important;
}

.modal-dialog:has(.modal-dc) {
  margin: auto;
}

.header-lang-nav-container {
  display: flex;
  align-items: center;
  column-gap: 15px;
  color: #334155 !important;
}

.header-lang-nav-container > div {
  cursor: pointer;
}

.header-lang-nav-container .custom-select-field {
  min-width: unset;
  padding: 4px;
  border-radius: 100px;
  margin-bottom: 0px;
}

.header-lang-nav-container .country-dropdown-container {
  width: auto;
  z-index: 1;
  right: 10px;
  top: 50px;
  margin-top: 5px;
}

/* .header-lang-nav-container .custom-select-field {
    font-size: 12px;
    font-weight: 400;
} */

.header-lang-nav-container .country-dropdown-container {
  font-size: 12px;
  font-weight: 400;
}

.header-lang-nav-container .country-dropdown-items .first {
  display: flex;
  align-items: center;
  grid-column-gap: 10px;
}

.header-lang-nav-container .country-dropdown-items {
  display: block;
}

/*.header-lang-nav-container .country-child-container {
        display: flex;
        align-items: center;
        justify-content: center;
        background-image: url(/static/media/welcome-bg.756c39a1.png) !important;
    }*/

.header-lang-nav-container .country-child-container > div {
  padding: 0px 8px;
  /* border-right: 1px solid #EBEBF566; */
  font-size: 14px;
  margin: 5px 0px;
  font-weight: 400;
}

.header-lang-nav-container .country-child-container > div:last-child {
  border: unset;
}

.header-lang-nav-container .country-child-container > div:first-child {
  padding-left: 0px;
}

.header-lang-nav-container .country-dropdown-container > .active {
  background: rgba(217, 217, 217, 0.06);
}

.header-lang-nav-container .country-dropdown-container {
  overflow: hidden;
}

.header-lang-nav-container .country-child-container .active {
  color: #334155;
  font-weight: bold;
}

.country-dropdown-container {
  background-color: #f2f5fb;
  /* background-image: url(/static/media/welcome-bg.756c39a1.png) !important; */
  /* background: linear-gradient(0deg, rgba(25, 35, 47, 0.2), rgba(25, 35, 47, 0.2)), linear-gradient(271.33deg, rgba(78, 75, 200, 0.3) 0%, rgba(254, 94, 171, 0.03) 100%); */
  box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  color: #94a3b8;
  font-size: 14px;
  position: absolute;
  width: 100%;
  z-index: 1;
}

.country-row-container {
  padding: 10px;
}

.text-capitalize {
  text-transform: capitalize;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-lowercase {
  text-transform: lowercase;
}

.text-capitalize {
  text-transform: capitalize;
}

.dashboard-noti {
  margin-right: 15px;
  /* position: absolute; */
  /* padding-left: 74%; */
  align-content: center;
}

.sweet-alert {
  margin: auto !important;
}

.theming-select {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: red;
}
.theming-select > div {
  cursor: pointer;
}
.theming-select > div:hover {
  color: aqua;
}
.header-notif-box {
  position: relative;
}
.header-notif-box .notif-red {
  width: 8px;
  height: 8px;
  background-color: red;
  border-radius: 100px;
  position: absolute;
  top: 0px;
  right: 0px;
}
.icon-circle-box {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #10182d;
  border: 1px solid rgb(51, 65, 85);
  border-radius: 100px;
  width: 48px;
  height: 48px;
}
/* ///janjan// */

:root {
  --html-main-width: 100%;
  --html-min-width: 500px;
  --theme-font-family-base: "Poppins", sans-serif;
  --theme-font-size-base: 14px;
  --theme-img-bg-base: url(../img/home/new/bg.png);
  --theme-color-bg-base: unset;
  --theme-color-bg-primary: #1b284b;
  --theme-color-bg-modal: #10182d;
  --theme-color-text-base: "#fff";
  --theme-color-text-primary: "#29d5d9";
  --theme-img-bg-account-container: "null";
  --theme-color-bg-account-container: #000000;
  --theme-color-bg-home-balance-tab-container: rgba(27, 40, 75, 0.5);
  --theme-color-bg-home-balance-tab-active-container: rgb(27, 40, 75);
  --theme-color-bg-home-balance-input-container: #05102f;
  --theme-color-bg-home-vip-container: linear-gradient(
    to bottom,
    #41a3ef,
    #e2f6fa
  );
  --theme-box-shadow-home-balance-header: inset 0 7px 10px -7px #1da1f2;
  --theme-box-shadow-home-balance-item: inset 0 -7px 10px -7px #1da1f2;
  --theme-box-shadow-home-bc-item: inset 0 2px 4px 0 rgba(29, 161, 242, 0.8);
  --theme-color-text-home-bc-item: "#29D5D9";
  --theme-color-bg-promo-vip-container: linear-gradient(
    180deg,
    rgba(17, 4, 42, 0) 0%,
    #11042a 58.33%,
    #11042a 100%
  );
  --theme-color-bg-promo-vip-container: linear-gradient(
    180deg,
    rgba(17, 4, 42, 0) 0%,
    #11042a 58.33%,
    #11042a 100%
  );
  --theme-color-home-game-link: "#131c34";
  --theme-color-home-game-active-link: "#131C34";
  --theme-color-icon-home-balance: #29d5d9;
  --theme-color-bg-all-box-with-icon: #1b284b;
  --theme-color-bg-search: #131c34;
  --theme-color-text-gray2: #94a3b8;
  --theme-color-bg-custom-form-input: #0d1426;
  --theme-color-bg-mytabs-active: #2c4077;
  --theme-color-icon-disabled: #94a3b8;
  --theme-color-text-gray: #64748b;
  --theme-color-bg-drop-down: #1b284b;
  --theme-color-bg-table-header: #10182d;
  --theme-color-bg-daily-checkin-icon-container: #1f2e56;
  --theme-color-bg-daily-checkin-day-container: #ffffff;
  --theme-color-bg-bottom-menu: rgb(15, 23, 42);
  --theme-color-bg-footer: #0f172a;
  --theme-color-text-bottom-menu-active: #29d5d9;
  --theme-color-text-gray4: #cbd5e1;
  --theme-color-bg-card-header: #002e6c;
  --theme-color-border-wallet-line: #343283;
  --theme-color-text-home-category: #94a3b8;
  --theme-border-home-bc-item-wrapper: unset;
  --theme-color-text-gray5: #cbd5e1;
  --theme-color-bg-vip-bottom: linear-gradient(
    to right,
    rgba(86, 0, 1, 0.1),
    rgb(86, 0, 1) 10%,
    rgb(86, 0, 1) 90%,
    rgba(86, 0, 1, 0.1)
  );
  --theme-color-bg-keyboard: #0b101e;
  --theme-color-bg-shop-item: linear-gradient(
    180deg,
    rgba(51, 105, 255, 0.24) -6.16%,
    rgba(27, 42, 84, 0.24) 110.96%
  );
  --theme-color-bg-pop-up: rgb(5, 16, 47);
  --theme-color-bg-reminder-yellow: rgba(111, 203, 32, 0.12);
  --theme-color-bg-body: #070a13;
  --theme-min-width-html: auto;
  --theme-color-text-table-th-header: #29d5d9;
  --theme-color-text-styled-select: #29d5d9;
}
.css-ion1xl-indicatorContainer {
  color: var(--theme-color-text-styled-select) !important;
}
.css-1bdogow-indicatorContainer {
  color: var(--theme-color-text-styled-select) !important;
}
.css-1ftc9ec-singleValue {
  color: var(--theme-color-text-styled-select) !important;
}
#root {
  height: 100%;
}
.css-n84pdy-menu > div > div {
  background: var(--theme-color-bg-all-box-with-icon) !important;
}
.css-n84pdy-menu {
  background: var(--theme-color-bg-all-box-with-icon) !important;
}
.reminder-yellow {
  background: var(--theme-color-bg-reminder-yellow);
}
.sweet-alert {
  background: var(--theme-color-bg-pop-up) !important;
}
.game-pts-item-card {
  background: var(--theme-color-bg-shop-item);
}
#Verify-qr .keyboard.keyboard-numpad .hg-button {
  background: var(--theme-color-bg-keyboard-pad);
}
#Verify-qr .react-simple-keyboard {
  background: var(--theme-color-bg-keyboard);
}
.comm-info-box .second {
  background: var(--theme-color-bg-table-header);
}
.comm-info-box .first {
  background: var(--theme-color-bg-all-box-with-icon);
}
.vip-membership-box {
  background: var(--theme-color-bg-vip-bottom);
}
.vip-benefits-box .second {
  background: var(--theme-color-bg-table-header);
}
.vip-benefits-box .first {
  background: var(--theme-color-bg-all-box-with-icon);
}
.custom-form .upload-file-box {
  color: var(--theme-color-text-primary);
}
.upload-file-box {
  background-color: var(--theme-color-bg-custom-form-input);
}
.reminder-green {
  color: var(--theme-color-text-base);
}
.text-lgray5 {
  color: var(--theme-color-text-gray5);
}
.home-game-category li.active a {
  color: var(--theme-color-text-primary);
}
.icon-circle-box .theme-icon-blue-size-20 {
  color: #4b4a4a;
}
.home-balance-input {
  color: var(--theme-color-text-base);
}
.home-balance-top-box > .first > .first {
  border-color: var(--theme-color-border-wallet-line);
}
.reminder-with-icon {
  color: var(--theme-color-text-base) !important;
}
#withdrawal-progress .text-white {
  color: var(--theme-color-text-base) !important;
}
.card-package {
  background: var(--theme-color-bg-all-box-with-icon);
}
.card-package .card-header {
  background: var(--theme-color-bg-card-header);
}
.home-header.shared-header .top-header {
  color: var(--theme-color-text-base) !important;
}
#Create-account .text-white {
  color: var(--theme-color-text-base) !important;
}
.bg-lblue {
  background: var(--theme-color-bg-all-box-with-icon) !important;
}
.default-lobby {
  background: var(--theme-color-bg-modal);
}
.reports-overview-list-box {
  background-color: var(--theme-color-bg-all-box-with-icon);
}
.report-final-balance-box {
  background: var(--theme-color-bg-search);
}
.earn-stat-number {
  color: var(--theme-color-text-base);
}
.earn-stat-title {
  color: var(--theme-color-text-gray4);
}
.reports-stats-box .ro-box-1-1 {
  background: var(--theme-color-bg-search);
}
.text-lgray4 {
  color: var(--theme-color-text-gray4);
}
.bottom-btn-wrapper .icon-title {
  color: var(--theme-color-text-bottom-menu) !important;
}
.icon.svg-icon .theme-icon-blue-size-20 {
  color: var(--theme-color-text-bottom-menu) !important;
}
.icon.svg-icon-active .theme-icon-blue-size-20 {
  color: var(--theme-color-text-bottom-menu-active) !important;
}
.bottom-btn-wrapper .icon-title.active {
  color: var(--theme-color-text-bottom-menu-active) !important;
}
#footer {
  background: var(--theme-color-bg-footer);
}
.bottom-btn-wrapper {
  background: var(--theme-color-bg-bottom-menu) !important;
}
.daily-check-in-day-pts {
  background: var(--theme-color-bg-daily-checkin-day-container);
}
.daily-check-in-middle-icon:before {
  background: var(--theme-color-bg-daily-checkin-icon-container);
}
.daily-check-in-middle-icon {
  background: var(--theme-color-bg-daily-checkin-icon-container);
}
.daily-check-in-container-items .text-white {
  color: var(--theme-color-text-base) !important;
}
.daily-check-in-container-items {
  background-color: var(--theme-color-bg-search);
}
.float-btn {
  background: var(--theme-color-bg-modal);
}
.referral-code-box {
  background: var(--theme-color-bg-search);
}
#kpi-select .css-bbcqiw-control {
  background: var(--theme-color-bg-all-box-with-icon);
}
#kpi-select .css-16hr6bx-control {
  background: var(--theme-color-bg-all-box-with-icon);
}
.all-flex-box-gray-50 > div {
  background: var(--theme-color-bg-search);
}
.referral-edit-boxes .first > div {
  background: var(--theme-color-bg-search);
}
.all-flex-box-gray .text-white {
  color: var(--theme-color-text-base) !important;
}
.table-kpi-summary tr th {
  background: var(--theme-color-bg-table-header);
}
.table-kpi-summary.sales-comission tr th:first-child {
  background: var(--theme-color-bg-all-box-with-icon);
}
.table-kpi-summary.sales-comission tbody td:first-child,
.table-kpi-summary.sales-comission tfoot td:first-child {
  background: var(--theme-color-bg-all-box-with-icon);
}
.table-kpi-summary tbody td,
.table-kpi-summary tfoot td {
  background: var(--theme-color-bg-table-header);
  color: var(--theme-color-text-gray2);
}
.text-lgray6 {
  color: var(--theme-color-text-gray);
}
.kpi-total-box .total-font-white {
  color: var(--theme-color-text-base);
}
.kpi-total-box > div > .second {
  background: var(--theme-color-bg-drop-down);
}
.kpi-total-box > div > .first {
  background: var(--theme-color-bg-search);
}
.add-remove-week-filter li {
  background-color: var(--theme-color-bg-search);
  color: var(--theme-color-text-gray);
}
.add-remove-week-filter li.active {
  background-color: var(--theme-color-bg-search);
  color: var(--theme-color-text-primary);
  border: 1px solid var(--theme-color-text-primary);
}
.accordion-drop-box {
  background: var(--theme-color-bg-mytabs-active);
}
.accordion-clicker-box {
  background: var(--theme-color-bg-all-box-with-icon);
}
.withdraw-to-box {
  background: var(--theme-color-bg-all-box-with-icon);
}
.withdraw-balance-box {
  background: var(--theme-color-bg-all-box-with-icon);
}
.urr-add-amount-box .urr-add-item {
  background: var(--theme-color-bg-primary);
}
.reload-bank-list-box {
  background: var(--theme-color-bg-all-box-with-icon);
}
.icon-circle-box {
  background: var(--theme-color-bg-modal);
  border-color: var(--theme-color-icon-home-balance);
}
.rollover-balance-box {
  background: var(--theme-color-bg-all-box-with-icon);
}
.input-with-balance {
  color: var(--theme-color-text-primary);
}
.urr-amount-box {
  background: var(--theme-color-bg-all-box-with-icon);
}
.all-flex-box-gray.bg-darkblue {
  background: var(--theme-color-bg-all-box-with-icon) !important;
}
.news-detail .text-brand {
  color: var(--theme-color-text-base) !important;
}
.news-item .text-white {
  color: var(--theme-color-text-base) !important;
}
#my-tabs li .active {
  background: var(--theme-color-bg-mytabs-active);
  color: var(--theme-color-text-primary);
}
#Verify-qr input.pincode-input-text {
  background: var(--theme-color-bg-all-box-with-ico) !important;
  color: var(--theme-color-text-primary);
}
.custom-form input {
  background-color: var(--theme-color-bg-custom-form-input) !important;
  color: var(--theme-color-text-base) !important;
}
.custom-form label {
  color: var(--theme-color-text-base);
}
.text-lgray2 {
  color: var(--theme-color-text-gray2);
}
.withdraw-accounts-modal .reload-bank-list-box {
  background: var(--theme-color-bg-all-box-with-icon);
}
.search-with-icon-box input {
  background: var(--theme-color-bg-search);
  color: var(--theme-color-text-base);
}
.home-header.shared-header .top-header i {
  color: var(--theme-color-text-base) !important;
}
body {
  background:#000;
  color: var(--theme-color-text-base);
  font-family: var(--theme-font-family-base);
  font-size: var(--theme-font-size-base);
}
.all-flex-box-gray {
  background: var(--theme-color-bg-all-box-with-icon);
}
.all-box-with-icon {
  background: var(--theme-color-bg-all-box-with-icon);
}
.text-lblue {
  color: var(--theme-color-text-primary);
}
.modal-content {
  background: var(--theme-color-bg-modal);
}
.theme-icon-size-20 {
  width: 20px;
  height: 20px;
  color: var(--theme-color-text-base);
}
.theme-icon-size-18{
  height: 18px;
  color: var(--theme-color-text-base);
}
.theme-icon-size-15 {
  width: 15px;
  height: 15px;
  color: var(--theme-color-text-base);
}
.theme-icon-gray-size-20 {
  width: 20px;
  height: 20px;
  color: var(--theme-color-icon-disabled);
}
.theme-icon-gray-size-15 {
  width: 15px;
  height: 15px;
  color: var(--theme-color-icon-disabled);
}
.theme-icon-blue-size-30 {
  width: 30px;
  height: 30px;
  color: var(--theme-color-icon-home-balance);
}
.theme-icon-blue-size-25 {
  width: 25px;
  height: 25px;
  color: var(--theme-color-icon-home-balance);
}
.theme-icon-blue-size-20 {
  width: 20px;
  height: 20px;
  color: var(--theme-color-icon-home-balance) !important;
}
.theme-icon-blue-size-15 {
  width: 15px;
  height: 15px;
  color: var(--theme-color-icon-home-balance);
}
.home-balance-top-box i {
  color: var(--theme-color-icon-home-balance);
}
.home-bc-item .first i {
  color: var(--theme-color-icon-home-balance);
}
.home-promo-vip-container > div:before {
  background: var(--theme-color-bg-promo-vip-container);
}
.home-bc-item .second {
  color: var(--theme-color-text-home-bc-item);
}
.page-sidebar-fixed.page-container {
  background-image: var(--theme-img-bg-base) !important;
  background-color: var(--theme-color-bg-base) !important;
}
.home-user-account-container {
  background-image: var(--theme-img-bg-account-container);
  background-color: var(--theme-color-bg-account-container);
}
.home-balance-container-tab-header {
  background: var(--theme-color-bg-home-balance-tab-container);
  color: var(--theme-color-text-base);
}
.home-balance-container-tab-header.active {
  background: var(--theme-color-bg-home-balance-tab-active-container);
  color: var(--theme-color-text-base);
}
.home-balance-container-tabs-items {
  background-color: var(--theme-color-bg-home-balance-tab-active-container);
}
.home-balance-container {
  background-color: var(--theme-color-bg-home-balance-input-container);
}
.home-bc-item-wrapper {
  background-color: var(--theme-color-bg-primary);
  border: var(--theme-border-home-bc-item-wrapper);
}
.all-section-box {
  color: var(--theme-color-text-base);
}
h6 {
  color: var(--theme-color-text-base);
}
h5 {
  color: var(--theme-color-text-base);
}
h4 {
  color: var(--theme-color-text-base);
}
h3 {
  color: var(--theme-color-text-base);
}
h2 {
  color: var(--theme-color-text-primary);
}
.vip-6 {
  background: var(--theme-color-bg-home-vip-container);
}
.home-balance-container-tabs-items {
  box-shadow: var(--theme-box-shadow-home-balance-item);
}
.home-balance-container-tab-header.active {
  box-shadow: var(--theme-box-shadow-home-balance-header);
}
.home-bc-item-wrapper {
  box-shadow: var(--theme-box-shadow-home-bc-item);
}
.home-promo-vip-container a {
  background: var(--theme-color-bg-primary);
  box-shadow: var(--theme-box-shadow-home-bc-item);
}
.home-game-category li.active a {
  background: var(--theme-color-home-game-active-link);
}
.home-game-category li a {
  background: var(--theme-color-home-game-link);
  color: var(--theme-color-text-home-category);
}
.home-popular-section .home-game-category li.active a {
  background: var(--theme-color-home-game-active-link);
  color: var(--theme-color-text-primary);
}

.page-container .content {
  background-image: var(--theme-img-bg-base) !important;
  background-color: var(--theme-color-bg-base) !important;
  background-size: 100%;
}

.game-pts-item-card .game-pts-item-card-body .game-pts-item-text {
  color: var(--theme-color-text-primary);
}

.custom-form input:hover,
.custom-form input:focus,
.custom-form input:active {
  border: 1px solid var(--theme-color-text-primary);
}

#Verify-qr input.pincode-input-text:hover {
  border: 1px solid var(--theme-color-text-primary) !important;
}

.upload-file-box .upload-file-box-content {
  border: 1px solid #29d5d9;
  border-radius: 4px;
  padding: 0 0.75rem;
  display: flex;
  flex-direction: row;
  min-width: 150px;
  width: 100%;
  height: 100%;
  min-height: 55px;
}

.upload-file-box .upload-file-box-content {
  border: 1px solid var(--theme-color-text-primary) !important;
}

.theme-icon-size-80 {
  width: 80px;
  height: 80px;
  color: var(--theme-color-text-base);
}

.crypto-address-text {
  overflow-wrap: break-word;
}

.crypto-details {
  width: 80%;
}


.language-dropdown .btn{
  background: var(--theme-color-bg-home-balance-tab-active-container) !important;
  border: none;
  font-size: 14px;
  font-weight: 600;
  box-shadow: var(--theme-box-shadow-home-bc-item);
  color: var(--theme-color-text-base);

}
.language-dropdown .dropdown-menu{
  background: var(--theme-color-bg-home-balance-tab-active-container) !important;
}
.language-dropdown .dropdown-item{
  color: var(--theme-color-text-base);
}
.language-dropdown .dropdown-item:hover{
  background-color: var(--theme-color-bg-home-balance-input-container);

}

.authenticate-container{
  min-height: 93px;
  padding: 12px 10px;
  border-radius: 10px;
  background-color:var(--theme-color-home-game-link);
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* box-shadow: var(--theme-box-shadow-home-balance-item); */
}
.authenticate-container a{
  flex: 0 0 48%;
  background: linear-gradient(270deg,rgba(41,213,217,.9),rgba(129,90,238,.9) 97.78%);
  /* box-shadow: var(--theme-box-shadow-home-bc-item); */
  padding: 15px;
  border-radius: 8px;
  text-align: center;
  color: #fff;
}
.authenticate-container .register{
  box-shadow: none;
  background: linear-gradient(270deg,rgb(235 15 255),rgb(170 10 239) 97.78%)
 
}

.fs-promo-info-button-container {
    display: flex;
    column-gap: 10px;
    position: fixed;
    bottom: 0px;
    width: 92%;
    background: var(--theme-color-bg-modal);
}

.btn-custom-danger {
    height: 48px !important;
    background: red;
    border: 0px;
    border-radius: 8px;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 12px 20px !important;
    gap: 10px;
    font-weight: 700 !important;
    color: #fff !important;
}

.background-color {
    background: var(--theme-color-bg-modal)
}

.frame {
    position:relative;
    border: 2px solid #3498db;
    border-radius:4px;
}

.active-word {
    position: absolute;
    z-index: 100;
    top: -13px;
    right: -12px;
    background-color: #3498db;
    color: #fff;
    border-radius: 50%;
    font-size: 10px;
    font-weight: bold;
    max-width:25%;
}

.redeem-shop-point-box {
    background: #181d36c9;
    border-radius: 10px;
    padding: 15px 0px 15px;
    margin-bottom: 5px;
    border: 1px #245758 solid;
}

.redeem-shop-point-box-width {
    width: 50%;
    align-content: center;
}

.redeem-shop-point-box-border {
    border-right: 1px #245758 solid;
    align-content: center;
    padding: 5px 0px;
}

.redeem-shop-h2{
    line-height:26px;
}

.quota-conversion-rate-box{
    overflow:auto
}

.quota-conversion-rate {
    float: right
}

.redeem-shop-point-box-content {
    justify-content: space-evenly;
    width: 100%;
    margin: 0px;
}

.redeem-shop-notice {
    padding: 10px 15px 0px;
    margin:0px
}
.redeem-border {
    border-bottom: 1px #42a0a2 solid;
    width:90%;
    margin:auto;
    margin-top:15px;
}

.redeem-box-noticee {
    width: 93%;
    background: #242f47;
    border-radius: 5px;
    padding: 5px 8px;
    margin:auto;
    margin-top:10px;

}






/**LIVE CHAT START**/
#chatroom {
    z-index: 1;
    position: absolute;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    left: 0;
    width: 100%;
    bottom: 59px;
    top: 0;
    background-color: #000;
    background-size: cover;
    background-position: center;
    color: #fff;
    padding-top: 10px;
}

.t3-custom-livechat-list-box {
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    height: calc(100% - 55px);
    padding-bottom: 35px;
}

.t3-custom-livechat-title {
    color: #45ff8b;
    font-size: 20px;
    font-weight: 600;
    line-height: 32px;
    margin: 12px auto;
    text-align: left;
}

.t3-livechat-list-box {
    text-align: left;
}

.t3-livechat-message-row {
    margin-bottom: 2px;
}

.t3-livechat-message-container {
    padding-left: 71px;
    padding-right: 57px;
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    -webkit-user-select: text;
}

    .t3-livechat-message-container.out {
        align-items: flex-end;
    }

.t3-livechat-message-wrapper {
    min-width: 40%;
    max-width: 65%;
    position: relative;
    flex: none;
    font-size: 14.2px;
    line-height: 19px;
    color: #fff;
    border-radius: 7.5px;
}


.t3-livechat-message-wrapper audio{
    width:100%;
}

.t3-live-chat-message-tail {
    left: -5px;
    position: absolute;
    top: 15px;
    z-index: 100;
    display: block;
    width: 15px;
    height: 10px;
    background: #3c3c3c;
    transform: rotate(45deg);
}

    .t3-live-chat-message-tail.out {
        right: -5px !important;
        left: unset;
    }

.t3-live-chat-message-profile-wrapper {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    left: -38px;
    position: absolute;
}

.t3-live-chat-message-profile {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    display: flex;
    background: #06cf9c;
    color: #ffffff;
}

.t3-live-chat-message-alphabet {
    font-size: 1.5rem;
}

    .t3-live-chat-message-alphabet > span {
        font-weight: 500;
        display: inline-block;
    }

.t3-livechat-message {
    background: #3c3c3c;
    color: #ffffff;
    box-shadow: 0 1px 0.5px rgba(11, 20, 26, 0.13);
    position: relative;
    z-index: 200;
    border-radius: 7.5px;
}

.t3-team-first-section {
    display: flex;
    column-gap: 20px;
}

    .t3-team-first-section > div > .first {
        background: #282828;
        border-radius: 20px;
        padding: 20px 20px 23px;
    }

    .t3-team-first-section > div {
        flex: 1 1;
    }

    .t3-team-first-section > .second {
        background: #282828;
        border-radius: 20px;
        padding: 20px 20px 23px;
    }

.t3-team-btn {
    background: var(--theme-color-bg-btn-special);
    border-radius: 0.75rem;
    color: #3c1100;
    font-size: 1.125rem;
    font-weight: 700;
    height: 55px;
    margin-top: 10px;
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
}

.custom-gray-box {
    background: #282828;
    border-radius: 0.75rem;
    padding: 20px;
}

.rebate-accordion-box {
    display: flex;
    gap: 20px;
}

    .rebate-accordion-box > .first {
        flex: 1 1;
        min-width: 60%;
    }

    .rebate-accordion-box > .second {
        flex: 1 1;
        display: flex;
        align-items: flex-end;
    }

@media (max-width: 1300px) {
    .t3-livechat-message-wrapper {
        max-width: 75%;
    }
}

@media (max-width: 900px) {
    .t3-livechat-message-wrapper {
        max-width: 95%;
    }

    .t3-livechat-message-container {
        padding-left: 53px;
        padding-right: 19px;
    }

    .footer-container {
        display: none;
    }

    .content.content-full-width {
        padding-bottom: 20px;
    }
}

.t3-livechat-message-container.out {
    padding-left: 19px;
}

.t3-livechat-content {
    padding-bottom: 8px;
    padding-top: 6px;
    padding-left: 9px;
    box-sizing: border-box;
    padding-right: 8px;
    -webkit-user-select: text;
}

.t3-livechat-username {
    display: inline-flex;
    max-width: 100%;
    font-size: 12.8px;
    line-height: 22px;
    font-weight: 500;
}

    .t3-livechat-username > span {
        min-height: 0px;
        flex: 0 1 auto;
        padding-left: 2px;
        margin-left: -2px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        visibility: visible;
        color: #45ff8b;
    }

    .t3-livechat-username.out > span {
        color: #f6c722;
    }

.t3-livechat-text-content > div {
    position: relative;
    word-wrap: break-word;
    white-space: pre-wrap;
}

.t3-livechat-text-content img {
    max-width: 200px;
    max-height: 200px;
    cursor: pointer;
}

.t3-livechat-text-content > div > span {
    min-height: 0px;
    visibility: visible;
    -webkit-user-select: text;
}

.t3-livechat-text-time {
    display: inline-flex;
    font-size: 0.6875rem;
    padding-right: 4px;
    line-height: 15px;
    padding-left: 4px;
    vertical-align: middle;
    padding-bottom: 0;
    padding-top: 0;
    float: right;
}

    .t3-livechat-text-time > span {
        flex-shrink: 0;
        flex-grow: 0;
    }

.t3-livechat-message-end {
    width: fit-content;
    bottom: 4px;
    align-self: flex-start;
    left: 6px;
    z-index: 200;
    display: flex;
    position: relative;
}

.t3-livechat-input-container {
    background: #292929;
    position: relative;
    z-index: 2;
    box-sizing: border-box;
    flex: none;
    order: 3;
    width: 100%;
    min-height: 55px;
    padding: 0;
    bottom: 0;
    position: absolute;
    z-index: 300;
}

.t3-livechat-input-wrapper {
    position: relative;
    z-index: 2;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    max-width: 100%;
    min-height: 55px;
    padding: 2px 10px;
}

.t3-livechat-input {
    position: relative;
    flex: 1;
    width: 100%;
    min-width: 0;
    min-height: 45px;
}

.t3-livechat-input-full {
    display: flex;
    align-items: center;
}

.t3-livechat-input-left {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 40px;
    padding: 0;
    color: yellow;
    will-change: width;
    transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out;
}

.t3-livechat-input-attachment-container {
    display: flex;
    align-items: center;
}

.t3-livechat-input-attachment-wrapper {
    margin: 0;
    position: relative;
    flex: none;
    height: 100%;
    border-radius: 50%;
}

.t3-livechat-input-attachment-button {
    display: flex;
    align-items: center;
}

    .t3-livechat-input-attachment-button.disabled {
        opacity: 0.3;
    }

/*.t3-livechat-input-attachment-button-icon {
    width: 24px;
    height: 24px;
    background: #abc;
    position: relative;
}

.t3-livechat-input-attachment-button-icon:before {
    content:'';
    display: block;
    position: absolute;
    width: 3px;
    height: 20px;
    background: red;
    top: 2px; 
    left:10px;
}

.t3-livechat-input-attachment-button-icon:after {
    content: '';
    display: block;
    position: absolute;
    width: 20px;
    height: 3px;
    background: red;
    top: 10px;
    left: 2px;
}*/

.t3-livechat-input-right {
    display: flex;
    flex: 1;
    align-items: center;
    min-width: 0;
}

.t3-livechat-input-textarea-container {
    box-sizing: border-box;
    flex: 1 1 auto;
    width: inherit;
    min-width: 0;
    min-height: 46px;
    padding: 5px 12px;
    margin: 5px 8px;
    font-size: 15px;
    font-weight: normal;
    line-height: 20px;
    background-color: #434343;
    border-color: #222;
    border-style: solid;
    border-width: 1px;
    border-radius: 5px;
    outline: none;
    will-change: width;
    display: flex;
    /* height: 36px; */
}

.t3-livechat-input-textarea-wrapper {
    width: 100%;
    position: relative;
    display: flex;
}

.t3-livechat-input-textarea {
    min-height: 36px;
    width: 100%;
    user-select: text;
    white-space: pre-wrap;
    word-break: break-word;
    overflow-x: hidden;
    overflow-y: auto;
    font-size: 1rem;
    line-height: 30px;
    background: transparent;
    border: none;
    resize: none;
    overflow: hidden;
    color: #fff;
    height: 100%;
}

    .t3-livechat-input-textarea::placeholder {
        color: #747474;
    }

    .t3-livechat-input-textarea:focus-visible {
        outline: none;
    }

.t3-livechat-input-send,
.t3-livechat-input-voice {
    width: 40px;
    min-width: 40px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 40px;
    padding: 0;
}

    .t3-livechat-input-send.disabled {
        opacity: 0.3;
    }

.t3-livechat-input-voice {
    margin-right: 8px;
}

    .t3-livechat-input-voice .disabled {
        opacity: 0.3;
    }

.active-tag {
  background: #29ae5f;
  color: #fff;
  border: 1px #29ae5f solid;
  -webkit-box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.3);
  padding: 3px 10px;
  border-radius: 25px;
  top: -20px;
  left: 10px;
  font-weight: bold;
}

    .active-tag.actived {
        display: block !important;
    }

@media (min-width: 768px) {
    #chatroom,
    .t3-loading-logo {
        left: 0;
        width: 100%;
        bottom: 59px;
        top: 0;
    }

    .t3-custom-livechat-list-box {
        padding-bottom: 20px;
    }
}

@media (max-width: 767px) {
    .mt-5 {
        margin-top: 30px !important;
    }

    .t3-game-category-box {
        justify-content: center;
    }

    .active-tag.actived {
        font-size: 10px;
        padding: 3px 10px;
        top: -11px;
    }
}

@media (max-width: 375px) {
    /* .t3-header-profile-box {
    display: none;
  } */
}

.t3-livechat-input-voice.recording {
    width: 100%;
}

    .t3-livechat-input-voice.recording > div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        align-content: center;
        flex-direction: row;
        width: 100%;
    }

@keyframes wavesAni {
    from {
        transform: translateX(0);
    }

    to {
        transform: translateX(-50%);
    }
}

.t3-audio-wave {
    width: 100%;
    overflow: hidden;
    height: 40px;
    display: block;
    position: relative;
    margin: 2px 10px 0;
}

.t3-audio-inner-wave {
    height: 35px;
    width: 500%;
    background: url(../img/icon/wave.png) repeat-x transparent !important;
    animation-name: wavesAni;
    animation-iteration-count: infinite;
    animation-duration: 30s;
    animation-timing-function: linear;
}

.t3-audio-cancel {
    margin-right: 8px;
}

.t3-recording-countdown {
    font-size: 18px;
    font-weight: bold;
}

.red-note {
    display: flex;
    align-items: center;
    justify-content: space-between;
    grid-column-gap: 10px;
    column-gap: 10px;
    color: rgba(255, 255, 255, 0.9);
}

    .red-note > .first {
        display: flex;
        align-items: center;
        column-gap: 10px;
    }

        .red-note > .first > .first {
            min-width: 30px;
            text-align: center;
        }

    .red-note .font14 {
        color: #ff493f;
    }

.img-teamBanner {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.t3-bottom-nav-item img {
    max-height: 60px;
    width: 100%;
}

.badge {
    border-radius: 50%;
    background-color: #ff0000;
    color: #ffffff;
    font-size: 12px;
    font-weight: bold;
    line-height: 17px;
    width: 17px;
    height: 17px;
    text-align: center;
    padding: 0;
    margin: 5px 0 0 30px;
}

.main-pulse {
    animation: pulse 0.6s infinite alternate;
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(1.2);
    }
}

.sidenav-unread-count {
    position: absolute;
    top: -8px;
    right: 30px;
    margin: 0;
    width: 20px;
    height: 20px;
    line-height: 20px;
}

.t3-sidemenu-item .sidenav-unread-count {
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
}

@media (min-width: 1251px) {
    .home-referral-container .t3-team-banner-code-box {
        top: 50%;
        transform: translate(0, -50%);
    }
}

.unavailable-image {
    -webkit-filter: grayscale(100%) brightness(63%);
    filter: grayscale(100%) brightness(63%);
    opacity: 0.8;
}

.t3-deposit-box-wrapper {
    background: var(--theme-color-bg-deposit);
    padding: 20px 20px 10px 20px;
    border-radius: 8px;
}

    .t3-deposit-box-wrapper label {
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        color: rgba(255, 255, 255, 1);
    }

/* .t3-wthdraw-input-style-box > .second input::placeholder {
  color: rgba(170, 170, 170, 1);
} */

.yellow-note-title {
    color: #f6c722;
    font-size: 14px;
    font-weight: 700;
}

.t3-withdrawal-input-text,
.t3-deposit-input-text {
    border-radius: 8px;
    background: rgba(255, 255, 255, 1) !important;
    border: 2px none;
    outline: none;
    height: 40px;
    padding: 0px 20px;
    font-size: 16px;
    width: 100%;
    color: rgba(135, 135, 135, 1) !important;
    font-weight: 400;
}

    .t3-withdrawal-input-text.disabled,
    .t3-deposit-input-text.disabled {
        pointer-events: none;
    }

    .t3-withdrawal-input-text::-webkit-input-placeholder,
    .t3-deposit-input-text::-webkit-input-placeholder {
        /* Edge */
        color: rgba(135, 135, 135, 1);
    }

    .t3-withdrawal-input-text:-ms-input-placeholder,
    .t3-deposit-input-text:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: rgba(135, 135, 135, 1);
    }

    .t3-withdrawal-input-text::placeholder,
    .t3-deposit-input-text::placeholder {
        color: rgba(135, 135, 135, 1) !important;
    }

    .t3-withdrawal-input-text[readonly],
    .t3-withdrawal-input-text:disabled,
    .t3-withdrawal-option-select[readonly],
    .t3-withdrawal-option-select:disabled,
    .t3-deposit-input-text[readonly],
    .t3-deposit-input-text:disabled,
    .t3-deposit-option-select[readonly],
    .t3-deposit-option-select:disabled {
        background: #aaaaaa !important;
        color: #262626 !important;
        opacity: 1 !important;
    }

.t3-withdrawal-option-select,
.t3-deposit-option-select {
    border-radius: 8px;
    background: var(--theme-color-bg-input-primary) !important;
    border: 2px none;
    outline: none;
    height: 40px;
    padding: 0px 20px;
    font-size: 16px;
    width: 100%;
    color: var(--theme-color-text-placeholder);
    font-weight: 400;
}

.t3-withdrawal-option-value,
.t3-deposit-option-value {
    color: var(--theme-color-text-primary);
}

.t3-withdrawal-option-value,
.t3-deposit-option-value {
    color: var(--theme-color-text-primary);
}

.t3-withdrawal-option-select.disabled::-ms-expand,
.t3-withdrawal-option-select.disabled::-webkit-inner-spin-button,
.t3-withdrawal-option-select.disabled::-webkit-outer-spin-button,
.t3-deposit-option-select.disabled::-ms-expand,
.t3-deposit-option-select.disabled::-webkit-inner-spin-button,
.t3-deposit-option-select.disabled::-webkit-outer-spin-button {
    display: none !important;
}

.t3-withdrawal-option-select:disabled,
.t3-deposit-option-select:disabled {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: none; /* Remove default arrow */
}

.mobile-home-latest-transactions-box > .body.empty-data {
    display: block !important;
    height: fit-content;
    padding: 10px;
}

.img-announcement {
    max-width: 100%;
    border-radius: 8px;
    display: inline-block;
}

.t3-withdraw-modal-body.bank-info,
.t3-deposit-modal-body.bank-info {
    background: #333;
    margin: 20px 0;
    padding: 0 !important;
}

.bank-info-separator-line {
    border: none;
    height: 1px;
    background: #5d5d5d;
    margin: 0 16px;
}

.t3-withdraw-modal-body.bank-info .t3-reload-bank-list-box,
.t3-deposit-modal-body.bank-info .t3-reload-bank-list-box {
    margin-bottom: 0;
}

.t3-withdraw-modal-body.upload-receipt,
.t3-deposit-modal-body.upload-receipt {
    margin: 20px 0 !important;
    padding: 16px 0 12px !important;
}

.t3-rollover-sweet-alert-header .first {
    font-size: 16px;
    font-weight: 700;
    text-align: left;
    color: #ffffff;
}

.t3-rollover-sweet-alert-body .title {
    font-size: 14px;
    font-weight: 700;
    color: #ffffff;
}

.t3-rollover-sweet-alert-body .amount {
    font-size: 14px;
    font-weight: 700;
    color: #f6c722;
}

.t3-rollover-sweet-alert-body .date {
    font-size: 12px;
    font-weight: 400;
    color: #ffffff;
}

.t3-rollover-sweet-alert-body .green-info {
    font-size: 12px;
    font-weight: 400;
    color: #45ff8b;
}

.t3-rollover-sweet-alert-info {
    display: flex;
    align-items: center;
    text-align: left;
    gap: 20px;
}

.bonus-container{
    display:flex;
    justify-content:space-between;
}

.rollover-balance-box2 {
  padding: 4px !important
}

.unavailable {
  -webkit-filter: grayscale(100%) brightness(63%);
  filter: grayscale(100%) brightness(63%);
  opacity: .8;
  -webkit-animation: none;
  animation: none;
  cursor: not-allowed !important;
}

.t3-custom-title {
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 0px;
}

.t3-custom-title span {
  color: #39A7CA;
}

@media (max-width: 767px) {
  .t3-custom-title {
    font-size: 1rem;
    line-height: 1.5;
  }
}
.mobile-latest-deposit-withdraw-box {
  display: flex;
}

.mobile-latest-deposit-withdraw-box > div {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.mobile-latest-deposit-withdraw-box h4 {
  font-size: 14px;
}

.mobile-latest-deposit-withdraw-box > div:first-child > .mobile-home-latest-transactions-box {
  border-radius: 8px 0px 0px 8px;
  border-right: 1px solid #5D5D5D;
  background: #131C34;
}

.mobile-latest-deposit-withdraw-box > div:last-child > .mobile-home-latest-transactions-box {
  border-radius: 0px 8px 8px 0px;
  background: #131C34;

}

.mobile-home-latest-transactions-box {
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: stretch;
}

.mobile-home-latest-transactions-box > .head {
  background: #1B284B;
  color: #39A7CA;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
}

.mobile-home-latest-transactions-box > .body {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  font-size: 14px;
  text-align: center;
  color: #fff;
  padding: 0px 10px;
  height: 30px;
  background: #131C34;
  border-bottom: 1px solid #292929;
}

.mobile-home-latest-transactions-box > .body.empty-data {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.mobile-home-latest-transactions-box > .odd {
  background: #131C34;
}

.mobile-home-latest-transactions-box > .even {
  background: #131C34;
}

.mobile-home-latest-transactions-box > .body > .latest-label {
  height: 100%;
  display: flex;
  align-items: center;
}

.mobile-home-latest-transactions-box > .body img {
  height: 80%;
  margin-right: 5px;
  object-fit: contain;
}

.payout-container{
  /* box-shadow: 0px 4px 10px 0px #00000040; */
  padding: 8px 10px;
  /* border: 1px solid #51B376; */
  /* box-shadow: 0px 0px 20px 0px #45FF8B33 inset; */
  border-radius: 12px;
  background: #131C34;

}
.payout-container .payout-list .item{
  background: #1B284B;
  border-radius: 8px;
  padding: 10px;
  text-align: left;
  margin: 0 0 15px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  box-shadow: 0px 0px 5.3px 2px #1C6BA8B2 inset;

  /* min-width: 165px;
  max-width: 200px; */
}

.payout-container .payout-list .item .item-sub-box{
  display: flex;
  gap:10px;
  align-items: center;

}

.payout-container .payout-list div:focus-visible{
  outline: unset !important;

}
.payout-list .item img{
  width: 60px;
  height: 60px;
  margin: 0;
}


.payout-list .item .title {
  font-size: 14px;
  font-weight: 700;
  color: #fff;
  margin-top: 0px;
}
.payout-list .item .username{
  font-size: 14px;
  font-weight: 400;
  color: #fff;
}
.payout-list .item .amount{
  font-size: 16px;
  font-weight: 700;
  color: #39A7CA;
}

.payout-container .gif{
  width: 50px;
  height: 50px;
}

.big-win-box{
  background: #10182D !important;
}

.big-win-box .amount{
  font-size: 18px;
  font-weight: 700;
  color: #39A7CA;
}

.big-win-box .bet-detail{
  background: #010510;
  border: 1px solid #334155;
  padding: 8px;
  display: flex;
  border-radius: 6px;
  width: 100%;
}
.big-win-box .label{
  color: #9E9E9E;
  padding: 0;
}
.big-win-box .bet-detail .left,
.big-win-box .bet-detail .right{
  flex: 0 0 50%;
  text-align: center;
}

.big-win-box .bet-detail .left{
  border-right: 1px solid #9E9E9E
}
.big-win-box .bottom{
  width: 100%;
  display: flex;
  align-items: center;
}
.big-win-box .bottom .left,
.big-win-box .bottom .right{
  flex: 0 0 50%
}

.big-win-box .bottom .right{
  text-align: right;
}
.big-win-box .link{
  color: #29D5D9;
  text-decoration: underline;
}

.deposit-amount{
  font-size: 22px;
  font-weight: 700;
  color: #29D5D9;
}

.reload-bank-details{
  max-width: 92%;
}

.badge-live-chat {
  position: relative;
  bottom: 29px;
  left: 24px;
  transform: translate(50%, -50%);
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 2px 6px;
  font-size: 12px;
  font-weight: bold;
  z-index: 1;
}

.kpi-pagination .pagination{
  float: right;
}

.kpi-pagination .previous.page-item{
  margin-right: 8px;
}
.kpi-pagination .next .page-link,
.kpi-pagination .previous .page-link{
  padding: 7px;
  background-color: #1B284B !important;
}
.kpi-pagination .next svg,
.kpi-pagination .previous svg{
  width: 16px;
  height: 16px;

}

.kpi-pagination .next.page-item{
  margin-left: 7px !important;
}
.kpi-pagination .pagination .page-item .page-link{
  border-radius: 0;
  background: #10182D;
  border: 0;
  color: #cbd5e1
}

.kpi-pagination .pagination .page-item.active .page-link,
.kpi-pagination .pagination .page-item .page-link:hover{
  background-color: #1B284B;
}

.kpi-pagination .pagination .page-item+.page-item{
  margin: 0;
}

.page-link:focus{
  box-shadow: none !important;
}

